import { useState } from "react";

import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import {
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    createTheme,
    ThemeProvider,
} from "@mui/material";

import {
    GridToolbarContainer,
    GridToolbarExport,
    useGridApiContext,
} from '@mui/x-data-grid-pro';

import { PrintList } from "../PrintList";
import {
    setPrintList,
    sendSavePatientsEvacuationStamp,
    sendGetEvacuationData,
} from "../../evacuationSlice";

import { DateTimeFld } from "../../../../components/Fields";




function Field({ column = {}, fieldsState = {}, setFieldsState = () => { } }) {
    let type = column.type || "string";
    let values = column.values?.length ? column.values : [];

    let dependencyValues = column.dependencyValues || null;
    let dependencyField = column.dependencyField || null;

    let dependencyFieldValue = dependencyField && fieldsState[dependencyField] ? fieldsState[dependencyField] : null;
    let hasDependency = dependencyValues ? !!dependencyValues[dependencyFieldValue] : false;
    let dependencyValuesList = hasDependency ? dependencyValues[dependencyFieldValue] : values;

    dependencyValuesList = dependencyValuesList || [];
    let disabled = false;

    if (hasDependency && dependencyValuesList.length === 1 && !dependencyValuesList[0]) {
        disabled = true;
        dependencyValuesList = [`Не применимо для такого значения поля "Статус убытия"`]
    }

    const handleChangeSelect = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setFieldsState({
            ...fieldsState,
            [name]: value,
        })
    }

    switch (type) {
        case "string":
            return <TextField
                name={column.field}
                label={column.headerName}
                fullWidth
            />;

        case "singleSelect":
            return <FormControl fullWidth>
                <InputLabel id="simple-select-label">{column.headerName}</InputLabel>
                <Select
                    labelId="simple-select-label"
                    onChange={handleChangeSelect}

                    name={column.field}
                    label={column.headerName}
                >
                    {dependencyValuesList.map(value => {
                        return <MenuItem value={value} disabled={disabled}>{value}</MenuItem>;
                    })
                    }
                </Select>
            </FormControl>;

        case "dateTime":
            return <DateTimeFld
                name={column.field}
                label={column.headerName}
                thema={{}}
                handleChange={() => { }}
                value={dayjs()}
            />;

        default:
            break;
    }

    return <TextField
        name={column.field}
        label={column.headerName}
        fullWidth
    />;

}


export function CustomToolbar({ selected = [], handleSave = () => { }, columnsToPrint = "", columnsToGroupChange = [] }) {
    console.log("columnsToPrint", columnsToPrint);
    console.log("columnsToGroupChange", columnsToGroupChange);

    const [fieldsState, setFieldsState] = useState({}); // Создаём единый state для зависимых полей, которые должны знать друг о друге
    const [list, setList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    // const [openModal, setOpenModal] = useState([]);
    const dispatch = useDispatch();

    const apiRef = useGridApiContext();


    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {

        setOpenModal(true);
    };

    let rowSelection = apiRef.current?.state?.rowSelection || [];

    /**
     * 
     */
    console.log(apiRef.current?.state);
    let allColumns = apiRef.current?.state?.columns?.lookup || {};
    // let visibleRowsLookup = apiRef.current?.state?.visibleRowsLookup || {};
    console.log(allColumns);

    let columns = [];

    columnsToGroupChange.forEach(key => {
        if (allColumns[key]) {
            columns.push(allColumns[key]);
        }
    });

    console.log(columns);
    console.log(fieldsState);

    return (<>
        {/** PrintList тут не нужен, но убирать аккуратно, тщательно протестировав печать, особенно отступы. Есть второй PrintList, он нужен*/}
        <PrintList list={list} />

        <Dialog
            fullWidth
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const modalData = Object.fromEntries(formData.entries());
                    dayjs.locale("ru");
                    console.log(modalData);
                    let d = null;
                    if (modalData.h300_date_action2) {
                        d = new Date(dayjs(modalData.h300_date_action2 ?? undefined, "DD.MM.YYYY HH:mm", { locale: "ru" }));
                        d = d.getFullYear() === 1 ? new Date() : d.toISOString();
                    }
                    const data = {
                        ...modalData,
                        h300_date_action2: d,
                        "h300_id": rowSelection,
                    };

                    // console.log(data);
                    dispatch(sendSavePatientsEvacuationStamp({
                        data,
                        success: () => {
                            // dispatch(sendGetEvacuationData());
                            window.location = "/evacuation";
                            // console.log(apiRef.current);
                            // apiRef.current.forceUpdate();
                        }
                    }))
                    handleCloseModal();

                },
            }}
        >

            <DialogContent>
                <DialogTitle>Выбрано для эвакуации: {rowSelection.length || 0}</DialogTitle>
                <DialogContentText>
                    {columns.map(column => {
                        return <Box sx={{ m: 1 }} key={column.field}>
                            <Field
                                column={column}
                                fieldsState={fieldsState}
                                setFieldsState={setFieldsState}
                            />
                        </Box>;
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal} variant="contained">Закрыть</Button>
                <Button type="submit" variant="contained">Применить данные ко всем отмеченным записям</Button>
            </DialogActions>
        </Dialog>


        <GridToolbarContainer sx={{ mb: 1 }}>
            <Button
                variant="outlined"
                size="small"
                disabled={!selected.length}
                // onClick={handleSave}
                onClick={handleOpenModal}
            >Завершить эвакуацию {`${selected.length ? ': ' + selected.length : ""}`}</Button>

            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant="outlined"
                size="small"
                onClick={() => {
                    let visibleRowsLookup = apiRef.current?.state?.visibleRowsLookup || {};
                    let sortedRows = apiRef.current.getSortedRows();

                    let visible = [];
                    sortedRows.forEach(item => {
                        if (visibleRowsLookup[item.h300_id]) {
                            visible.push(item);
                        }
                    });

                    dispatch(setPrintList(visible));

                    setTimeout(() => {
                        window.print();
                    }, 600);
                }}
            >Распечатать</Button>
            <GridToolbarExport
                printOptions={{
                    disableToolbarButton: true,
                    hideToolbar: true,
                    hideFooter: true,
                    // getRowsToExport: (wer, wefr, wferr) => {
                    // console.log(wer, wefr, wferr);
                    // return ["444"];
                    // },
                    fields: columnsToPrint,
                }}
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ";",
                }}
                slotProps={{
                    tooltip: { title: 'Экспорт' },
                    button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    </>
    );
}