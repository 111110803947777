import React, { memo } from "react";
import dayjs from 'dayjs';

import { fields } from '../../../../../app/thema';
import {
    Box,
    Grid,
} from '@mui/material';

import { DateFldNew, TextFld, PhoneFld } from '../../../../../components/Fields';
import { AutoCompleteCbx } from '../../../../../components/ComboBoxs';


export const listModel = {
    suid: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'suid',
        label: 'Номер жетона',
    },
    surName: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'surName',
        label: 'Фамилия',
    },
    name: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'name',
        label: 'Имя',
    },
    patronymic: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'patronymic',
        label: 'Отчество',
    },
    birthday: {
        component: DateFldNew,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'birthday',
        label: 'Дата рождения',
    },
    phoneNumber: {
        component: PhoneFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'phoneNumber',
        label: 'Контактный телефон',
        mask: '+7(999)999-99-99'
    },
    militaryUnitCode: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'militaryUnitCode',
        label: 'Воинская часть',
    },
    division: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'division',
        label: 'Подразделение',
    },
    rota: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'rota',
        label: 'Рота',
    },
    platoon: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'platoon',
        label: 'Взвод',
    },
    militaryDep: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'militaryDep',
        label: 'Отделение',
    },
    militaryRank: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'militaryRank',
        label: 'Воинское звание',
    },

}

const ListItemComponent = ({ title, formik, lists = {} }) => {
    let key = title.fieldKey;

    // Соответствие приходящих и уходящих ключей
    const elems = {
        militaryRank: lists.militaryRank,
        militaryUnitCode: lists.militaryPart,
        division: lists.militaryUnit,
        rota: lists.militaryCompany,
        platoon: lists.militaryPlatoon,
        militaryDep: lists.militaryDepartment,
    }

    const handleChangeDate = (key, val) => {
        let d;
        if (!dayjs(val).isValid()) {
            d = null;
        } else {
            d = new Date(dayjs(val));
            d = d.toISOString();
        }
        formik.setFieldValue(key, d);
    }

    let FieldComponent = null;

    if (listModel[key] && listModel[key].component) {
        FieldComponent = listModel[key].component;
    }

    return <Grid
        item xs={12} sm={12} md={12} lg={6} xl={6}
    >
        <Box
            sx={{ m: 0 }}>
            <FieldComponent
                size={'small'}
                variant={'outlined'}
                thema={listModel[key].thema}
                type={listModel[key].type}
                mask={listModel[key].mask}
                name={key}
                rows={listModel[key].rows || ""}
                value={formik.values[key] || null}
                isDisabled={false}
                label={title.label}
                handleBlur={formik.handleBlur}
                handleChange={title.type === "date" ? (val) => handleChangeDate(key, val) : formik.handleChange}
                touched={formik.touched[key]}
                error={formik.errors[key]}
                elems={elems[key] || []}
                val={formik.values[key]}
                setFieldValue={formik.setFieldValue}
                handleSetState={() => { }}
            />
        </Box>
    </Grid>;
}


export const ListItem = memo(ListItemComponent);
