import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import SelectorForms from '../widgets/SelectorForms';
import FormaByEvent from '../widgets/FormaByEvent';
import FormaByPatient200 from '../widgets/FormaByPatient200';


export default function PRegistration(props) {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            {(() => {
                switch (props.eventType) {
                    // Ранение
                    case 1:
                    // Травма
                    case 2:
                    // Заболевание
                    case 3:
                    // Ядерное
                    case 4:
                    // Бактериальное
                    case 5:
                    // Химическое
                    case 6:
                    // Ожог
                    case 7:
                    // Отморожение
                    case 8:
                    // Инфекция
                    case 9:
                        return <FormaByEvent eventType={props.eventType}/>
                    // Смерть
                    case 10:
                        return <FormaByPatient200 />                    
                    default:
                        return <SelectorForms />
                }
            })()}
        </>
    )
}