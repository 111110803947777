import React, { useState } from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { appStorage } from "../helpers";

import { RegPersonnelAsync, FindByTokenNumberAsync, FindByCallSignAsync } from '../common/api/Personnel';
import {
    Get300Async, Reg300Async, Upd300Async, Get300ListAsync, GetEventAsync,
    RegEventAsync
} from '../common/api/RegForms';
import { MilitaryHandbooksAsync, Handbook300Async } from '../common/api/Handbook';

import { urlPatientRegistration } from '../common/helper/UrlRouter';
import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';

import { Grid, Card, Typography, Box } from '@mui/material';
import { DateTimeDesktopFld, MultilineFld, TextFld } from '../components/Fields';
import { AutoCompleteCbx } from '../components/ComboBoxs';
import { StartIconBtn, EndIconBtn } from '../components/Buttons';
import { CircularRightPrg } from '../components/Progress';
import { PersonnalByInsertDlg, EventByInsertDlg } from '../components/Dialogs';

import { TokenNumber } from './TokenNumber';
import { FullNameInput } from './FullNameInput';
import { FullNameShow } from './FullNameShow';
import { ComboBoxEdit } from './ComboBoxEdit';

import { fields, buttons, dialogs } from '../app/thema';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

import gravityOfInjuryJson from '../shared/gravityOfInjury.json';
import stayModeJson from '../shared/stayMode.json';


/**
 * Виджет формы регистрации пациента код 300
 * @returns виджет
 */
export default function FormaByEvent(props) {
    const navigate = useNavigate();

    const dtNotValid = 'Выбранная вами дата и время, еще не наступили!';
    const dtActionNotValid = 'Выбранная вами дата и время, не может быть раньше даты и времени ранения!';

    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [idEvent, setIdEvent] = useState('');
    const [idHistory, setIdHistory] = useState('');

    let isValid = true;
    let isOnline = true;


    // Формирование модели от типа
    const GetModelByType = () => {
        let val = {
            type: 'none',
            title: '',
            dateOfInjury: '',
            gravityOfInjury: '',
            locationWhereInjury: '',
            circumstances: '',
            natureOfDiagnosis: '',
        };
        switch (props.eventType) {
            case 1:
                val.type = 'Injury';
                val.title = 'Ранение';
                val.dateOfInjury = 'Время ранения';
                val.gravityOfInjury = 'Тяжесть ранения';
                val.locationWhereInjury = 'Где получил ранение';
                val.circumstances = 'Обстоятельства ранения';
                val.natureOfDiagnosis = 'Характер ранения';
                break;
            case 2:
                val.type = 'Trauma';
                val.title = 'Травма';
                val.dateOfInjury = 'Время травмы';
                val.gravityOfInjury = 'Тяжесть травмы';
                val.locationWhereInjury = 'Где получил травму';
                val.circumstances = 'Обстоятельства травмы';
                val.natureOfDiagnosis = 'Характер травмы';
                break;
            case 3:
                val.type = 'Disease';
                val.title = 'Заболевание';
                val.dateOfInjury = 'Время заболевания';
                val.gravityOfInjury = 'Тяжесть заболевания';
                val.locationWhereInjury = 'Где получил заболевания';
                val.circumstances = 'Обстоятельства заболевания';
                val.natureOfDiagnosis = 'Характер заболевания';
                break;
            case 4:
                val.type = 'Nuclear';
                val.title = 'Ядерное';
                break;
            case 5:
                val.type = 'Bacterial';
                val.title = 'Бактериальное';
                break;
            case 6:
                val.type = 'Chemical';
                val.title = 'Химическое';
                break;
            case 7:
                val.type = 'Burn';
                val.title = 'Ожог';
                break;
            case 8:
                val.type = 'Frostbite';
                val.title = 'Отморожение';
                break;
            case 9:
                val.type = 'Infectious';
                val.title = 'Инфекция';
                break;
        }

        return val;
    };

    // Список военских частей
    const [militaryPartJson, setMilitaryPartJson] = useState([]);
    const [militaryPart, setMilitaryPart] = useState({ id: '', label: '' });
    const [isEditMilitaryPath, setIsEditMilitaryPath] = useState(false);
    const handlerSetMilitaryPartList = (val) => {
        setMilitaryPartJson(val);
        if (formik.values.militaryUnit === '') {
            setMilitaryPart(val[0]);
        }
    };
    const handleClickMilitaryPath = () => {
        setIsEditMilitaryPath(!isEditMilitaryPath);
    };

    // Список подразделений
    const [militaryUnitJson, setMilitaryUnitJson] = useState([]);
    const [militaryUnit, setMilitaryUnit] = useState({ id: '', label: '' });
    const [isEditMilitaryUnit, setIsEditMilitaryUnit] = useState(false);
    const handlerSetMilitaryUnitList = (val) => {
        setMilitaryUnitJson(val);
        if (formik.values.division === '') {
            setMilitaryUnit(val[0]);
        }
    };
    const handleClickMilitaryUnit = () => {
        setIsEditMilitaryUnit(!isEditMilitaryUnit);
    };

    // Список рот
    const [militaryCompanyJson, setMilitaryCompanyJson] = useState([]);
    const [militaryCompany, setMilitaryCompany] = useState({ id: '', label: '' });
    const [isEditMilitaryCompany, setIsEditMilitaryCompany] = useState(false);
    const handlerSetMilitaryCompanyList = (val) => {
        setMilitaryCompanyJson(val);
        if (formik.values.rota === '') {
            setMilitaryCompany(val[0]);
        }
    };
    const handleClickMilitaryCompany = () => {
        setIsEditMilitaryCompany(!isEditMilitaryCompany);
    };

    // Список взводов
    const [militaryPlatoonJson, setMilitaryPlatoonJson] = useState([]);
    const [militaryPlatoon, setMilitaryPlatoon] = useState({ id: '', label: '' });
    const [isEditMilitaryPlatoon, setIsEditMilitaryPlatoon] = useState(false);
    const handlerSetMilitaryPlatoonList = (val) => {
        setMilitaryPlatoonJson(val);
        if (formik.values.platoon === '') {
            setMilitaryPlatoon(val[0]);
        }
    };
    const handleClickMilitaryPlatoon = () => {
        setIsEditMilitaryPlatoon(!isEditMilitaryPlatoon);
    };

    // Cписок отделений
    const [militaryDepartmentJson, setMilitaryDepartmentJson] = useState([]);
    const [militaryDepartment, setMilitaryDepartment] = useState({ id: '', label: '' });
    const [isEditMilitaryDepartment, setIsEditMilitaryDepartment] = useState(false);
    const handlerSetMilitaryDepartmentList = (val) => {
        setMilitaryDepartmentJson(val);
        if (formik.values.department === '') {
            setMilitaryDepartment(val[0]);
        }
    };
    const handleClickMilitaryDepartment = () => {
        setIsEditMilitaryDepartment(!isEditMilitaryDepartment);
    };

    // Список воинских званий
    const [militaryRankJson, setMilitaryRankJson] = useState([]);
    const [militaryRank, setMilitaryRank] = useState({ id: '', label: '' });
    const [isEditMilitaryRank, setIsEditMilitaryRank] = useState(false);
    const handlerSetMilitaryRankList = (val) => {
        setMilitaryRankJson(val);
        if (formik.values.actualRank === '') {
            setMilitaryRank(val[0]);
        }
    };
    const handleClickMilitaryRank = () => {
        setIsEditMilitaryRank(!isEditMilitaryRank);
    };

    const formatBirthday = (birthday = "") => {
        return birthday.substring(0, birthday.indexOf('T'));
    };
    const handleSetBirthday = () => {
        let year = (new Date().getFullYear()) - 30;
        let dt = new Date(year, 1).toISOString();
        dt = formatBirthday(dt);
        formik.setFieldValue(model.birthday.name, dt);
    };

    // Время ранения
    const handleSetDateOfInjury = (val) => {
        val = dayjs(val ?? null).isValid() ? new Date(val) : new Date();
        let dtNow = new Date();
        if (val > dtNow) {
            val = dtNow;
            setDateOfInjury(val);
            alertInfo(dtNotValid);

            return;
        }
        setDateOfInjury(val);
    };
    const setDateOfInjury = (val) => {
        formik.setFieldValue(model.dateOfInjury.name, val.toISOString());
    };

    // Тяжесть ранения
    const [gravityOfInjury, setGravityOfInjury] = useState(gravityOfInjuryJson[0]);

    // Место ранения
    const [locationWhereInjuryJson, setLocationWhereInjuryJson] = useState([]);
    const [locationWhereInjury, setLocationWhereInjury] = useState({ id: '', label: '' });
    const [isEditLocationWhereInjury, setIsEditLocationWhereInjury] = useState(false);
    const handlerSetLocationWhereInjuryList = (val) => {
        setLocationWhereInjuryJson(val);
        if (formik.values.locationWhereInjury?.label === '') {
            setLocationWhereInjury(val[0]);
        }
    };
    const handleClickLocationWhereInjury = () => {
        setIsEditLocationWhereInjury(!isEditLocationWhereInjury);
    };

    // Время поступления / обращения
    const handleSetDataAction = (val) => {
        let dt1 = new Date(dayjs(val ?? undefined));
        let dtNow = new Date();
        if (dt1 > dtNow) {
            dt1 = dtNow;
            setDateAction(dt1);
            alertInfo(dtNotValid);
            return;
        }

        let dt2 = new Date(dayjs(formik.values.dateOfInjury ?? undefined));
        if (dt1 < dt2) {
            setDateAction(dtNow);
            alertInfo(dtActionNotValid);
            return;
        }

        setDateAction(dt1);
    };
    const setDateAction = (val) => {
        formik.setFieldValue(model.dateAction.name, val.toISOString());
    };

    // Место оказания медицинской помощи
    const [locationMedicalAssistanceJson, setLocationMedicalAssistanceJson] = useState([]);
    const [locationMedicalAssistance, setLocationMedicalAssistance] = useState({ id: '', label: '' });
    const [isEditLocationMedicalAssistance, setIsEditLocationMedicalAssistance] = useState(false);
    const handlerSetLocationMedicalAssistanceList = (val) => {
        setLocationMedicalAssistanceJson(val);
        if (val?.length > 0) {
            formik.values.locationMedicalAssistance = val[0];
            setLocationMedicalAssistance(val[0]);
        }
    };
    const handleClickLocationMedicalAssistance = () => {
        setIsEditLocationMedicalAssistance(!isEditLocationMedicalAssistance);
    };

    // Диагноз при поступлении
    let isDiagnosisSelected = false;
    const handleClickDiagnosis = (event) => {
        let diagnosis = formik.values.diagnosis.trim();
        let natureOfDiagnosis = formik.values.natureOfDiagnosis.trim();
        if (!isDiagnosisSelected && diagnosis === '' && natureOfDiagnosis !== '') {
            isDiagnosisSelected = true;
            formik.setFieldValue(model.diagnosis.name, formik.values.natureOfDiagnosis);
        }
    };

    // Лечащий врач
    let LSDoctors = appStorage.getItem("doctors") || [];
    let doctors = appStorage.getItem("doctors") || [];
    let doctorsHelpers = [];
    const handleClickDoctorHelper = (val) => {
        formik.setFieldValue(model.doctor.name, val ?? '');
    };

    // Статус
    const [stayMode, setStayMode] = useState(stayModeJson[0]);

    // Загрузчик данных для страницы
    const handlerDataLoader = async () => {
        let handbook = await MilitaryHandbooksAsync();
        // Валидация
        if (handbook.result) {
            handlerSetMilitaryPartList(handbook.result.militaryPart);
            handlerSetMilitaryUnitList(handbook.result.militaryUnit);
            handlerSetMilitaryCompanyList(handbook.result.militaryCompany);
            handlerSetMilitaryPlatoonList(handbook.result.militaryPlatoon);
            handlerSetMilitaryDepartmentList(handbook.result.militaryDepartment);
            handlerSetMilitaryRankList(handbook.result.militaryRank);
        }

        let handbook300 = await Handbook300Async();
        // Валидация
        if (handbook300.result) {
            const locationMedicalAssistance = handbook300.result.locationMedicalAssistance.length
                ? handbook300.result.locationMedicalAssistance
                : [{ id: '', label: '' }];
            handlerSetLocationMedicalAssistanceList(locationMedicalAssistance);
            const locationWhereInjury = handbook300.result.locationWhereInjury.length
                ? handbook300.result.locationWhereInjury
                : [{ id: '', label: '' }];
            handlerSetLocationWhereInjuryList(locationWhereInjury);
        }
    };

    // Обработка поля номер жетона
    const [tokenNumberOk, setTokenNumberOk] = useState(false);
    const [isVisibleFullName, setIsVisibleFullName] = useState(false);
    const [fullNameValue, setFullNameValue] = useState('');
    const [personnels, setPersonnels] = useState([]);
    const [event, setEvent] = useState([]);
    const isTokenNumber = (fl) => {
        if (fl) {
            // Номер жетона был найден
            setTokenNumberOk(true);
            setIsVisibleFullName(false);

            return;
        }

        // Номер жетона не был найден
        setTokenNumberOk(false);
        setIsVisibleFullName(true);
    };
    const handleTokenNumber = async () => {
        handleSetBirthday();
        let res = await FindByTokenNumberAsync(formik.values.tokenNumber);
        // Валидация запроса
        if (!IsCode200(res) || res.result?.length == 0) {
            isTokenNumber(false);
            setStep(1);

            return;
        }

        setPersonnels(res.result);
        setPersonnalDlgOpen(true);
    };
    const handleNextClick = async () => {
        setLoading(true);
        switch (step) {
            case 0:
                let tokenToCheck = formik.values.tokenNumber;
                tokenToCheck = tokenToCheck.replace(/\s/g, '');
                tokenToCheck = tokenToCheck.replace(/-/g, '');
                // Основные форматы
                let match = [...tokenToCheck.matchAll(/^([а-яА-ЯёЁ]{1,4})-?(\d{4,10})$/gmi)];

                if (match[0] && match[0][0] && match[0][1] && match[0][2]) {
                    let letters = match[0][1].toUpperCase();
                    let number = match[0][2];
                    let fixedTokenNumber = letters + '-' + number;

                    formik.setFieldValue(model.tokenNumber.name, fixedTokenNumber);
                    formik.values.tokenNumber = fixedTokenNumber;
                    formik.setErrors({ tokenNumber: null });
                    // неосновной формат - только цифры
                } else if (tokenToCheck.match(/^\d{4,10}$/gmi)) {
                    // ничего не делаем, нас устраивает то, что заполнено
                    // какой-то неизвестный формат
                } else {
                    formik.setErrors({ tokenNumber: "Неверный формат жетона (А-99999, АА-999999, ААА-999999, 9999)" });
                    break;
                }

                await handlerDataLoader();
                await handleTokenNumber();
                break;
            default:
                break;
        }
        setLoading(false);
    };
    const handleTokenNumberKeyUp = (e) => {
        let code = e.keyCode || 0;
        if (code !== 13) return;

        handleNextClick();
    };
    const handleTokenNumberClick = () => {
        setTokenNumberOk(false);
        setStep(0);
    };

    // Валидация полей при добавлении    
    const fieldsPersonnelIsValid = () => {
        let tokenNumber =
            (formik.values.tokenNumber !== null &&
                formik.values.tokenNumber !== undefined &&
                formik.values.tokenNumber !== '' &&
                formik.values.tokenNumber !== ' ')
                ? true
                : false;
        return tokenNumber;
    };
    // Валидация полей при добавлении
    const fieldsInsertIsValid = () => {
        let locationWhereInjury =
            (formik.values.locationWhereInjury !== null &&
                formik.values.locationWhereInjury !== undefined &&
                formik.values.locationWhereInjury !== '' &&
                formik.values.locationWhereInjury !== ' ')
                ? true
                : false;
        let circumstances =
            (formik.values.circumstances !== null &&
                formik.values.circumstances !== undefined &&
                formik.values.circumstances !== '' &&
                formik.values.circumstances !== ' ')
                ? true
                : false;
        let natureOfDiagnosis =
            (formik.values.natureOfDiagnosis !== null &&
                formik.values.natureOfDiagnosis !== undefined &&
                formik.values.natureOfDiagnosis !== '' &&
                formik.values.natureOfDiagnosis !== ' ')
                ? true
                : false;
        let gravityOfInjury =
            (formik.values.gravityOfInjury !== null &&
                formik.values.gravityOfInjury !== undefined &&
                formik.values.gravityOfInjury !== '' &&
                formik.values.gravityOfInjury !== ' ')
                ? true
                : false;

        return (locationWhereInjury || natureOfDiagnosis || circumstances || gravityOfInjury);
    };
    // Добавление записи в журналы
    const handleRecordInsert = async () => {
        // Добавление записи в личный состав
        let idPersonnel = isVisibleFullName ? '' : selectedPersonnalValue.id;
        if (isVisibleFullName) {
            // Валидация обязательных полей
            if (!fieldsPersonnelIsValid()) {
                isValid = false;
                alertInfo('Не все обязательные поля заполнены!');

                return;
            }

            let militaryPath = isEditMilitaryPath ? formik.values.militaryUnitEdit : formik.values.militaryUnit?.label;
            let militaryUnit = isEditMilitaryUnit ? formik.values.divisionEdit : formik.values.division?.label;
            let militaryCompany = isEditMilitaryCompany ? formik.values.rotaEdit : formik.values.rota?.label;
            let militaryPlatoon = isEditMilitaryPlatoon ? formik.values.platoonEdit : formik.values.platoon?.label;
            let militaryDepartment = isEditMilitaryDepartment ? formik.values.departmentEdit : formik.values.department?.label;
            let militaryRank = isEditMilitaryRank ? formik.values.actualRankEdit : formik.values.actualRank?.label;
            let personnel = {
                tokenNumber: formik.values.tokenNumber,
                callSign: null,
                surname: formik.values.surname,
                name: formik.values.name,
                patronymic: formik.values.patronymic,
                birthday: formik.values.birthday
                    ? new Date(formik.values.birthday).toISOString()
                    : null,
                phoneNumber: formik.values.phoneNumber,
                militaryUnit: militaryPath,
                division: militaryUnit,
                rota: militaryCompany,
                platoon: militaryPlatoon,
                department: militaryDepartment,
                actualRank: militaryRank,
                wus: null,
            };
            let res = await RegPersonnelAsync(personnel);
            // Валидация запроса
            if (!IsCode200(res)) {
                isOnline = false;
                if (IsCode503(res)) {
                    alertError('Сервис недоступен!');
                    setLoading(false);

                    return;
                };

                alertWarn('Не удалось создать запись!');
                setLoading(false);

                return;
            }
            idPersonnel = res.result;
        }

        // Валидация обязательных полей
        if (!fieldsInsertIsValid()) {
            isValid = false;
            alertInfo('Заполните поля формы в начале!');

            return;
        }

        let dateOfInjury = formik.values.dateOfInjury === ''
            ? null
            : new Date(formik.values.dateOfInjury).toISOString();
        let locationWhereInjury = isEditLocationWhereInjury
            ? formik.values.locationWhereInjuryEdit
            : formik.values.locationWhereInjury?.label;
        let dateAction = formik.values.dateAction === ''
            ? null
            : new Date(formik.values.dateAction).toISOString();
        let locationMedicalAssistance = isEditLocationMedicalAssistance
            ? formik.values.locationMedicalAssistanceEdit
            : formik.values.locationMedicalAssistance?.label;
        let item = {
            eventType: GetModelByType().type,
            idPersonnel: idPersonnel,

            dateOfInjury: dateOfInjury,
            gravityOfInjury: formik.values.gravityOfInjury?.label,
            locationWhereInjury: locationWhereInjury ?? null,
            circumstances: formik.values.circumstances,
            nature: formik.values.natureOfDiagnosis,

            dateOfVisits: dateAction,
            locationMedicalAssistance: locationMedicalAssistance ?? null,
            complaint: formik.values.complaint,
            diagnosis: formik.values.diagnosis,
            diagnosticStudies: formik.values.diagnosticStudies,
            helpProvided: formik.values.assistanceProvided,
            pharmacotherapy: formik.values.pharmacotherapy,
            recommendations: formik.values.recommendations,
            doctor: formik.values.doctor,
            stayMode: formik.values.stayMode?.enum,
            hospitalWard: formik.values.hospitalWard,
        };
        let res = await RegEventAsync(item);
        // Валидация запроса
        if (!IsCode200(res)) {
            isOnline = false;
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setLoading(false);

                return;
            };

            alertWarn('Не удалось создать запись!');
            setLoading(false);

            return;
        }

        isValid = true;
        alertSuccess('Запись успешно добавлена!');
    };

    // Валидация полей при обновлении
    const fieldsUpdateIsValid = () => {
        let locationMedicalAssistance =
            (formik.values.locationMedicalAssistance !== null &&
                formik.values.locationMedicalAssistance !== undefined &&
                formik.values.locationMedicalAssistance !== '' &&
                formik.values.locationMedicalAssistance !== ' ')
                ? true
                : false;
        let diagnosis =
            (formik.values.diagnosis !== null &&
                formik.values.diagnosis !== undefined &&
                formik.values.diagnosis !== '' &&
                formik.values.diagnosis !== ' ')
                ? true
                : false;
        let assistanceProvided =
            (formik.values.assistanceProvided !== null &&
                formik.values.assistanceProvided !== undefined &&
                formik.values.assistanceProvided !== '' &&
                formik.values.assistanceProvided !== ' ')
                ? true
                : false;
        let recommendations =
            (formik.values.recommendations !== null &&
                formik.values.recommendations !== undefined &&
                formik.values.recommendations !== '' &&
                formik.values.recommendations !== ' ')
                ? true
                : false;

        return (diagnosis || locationMedicalAssistance || assistanceProvided || recommendations);
    };
    // Обновление записи в журнале
    const handleRecordUpdate = async () => {
        // Валидация полей
        if (!fieldsUpdateIsValid()) {
            isValid = false;
            alertInfo('Не все обязательные поля истории болезни заполнены!');

            return;
        }

        let dateOfInjury = formik.values.dateOfInjury === undefined || formik.values.dateOfInjury === null ||
            formik.values.dateOfInjury === ''
            ? null
            : new Date(formik.values.dateOfInjury).toISOString();
        let gravityOfInjury = formik.values.gravityOfInjury?.label;
        let locationWhereInjury = isEditLocationWhereInjury
            ? formik.values.locationWhereInjuryEdit
            : formik.values.locationWhereInjury?.label;
        let dateAction = formik.values.dateAction === undefined || formik.values.dateAction === null ||
            formik.values.dateAction === ''
            ? null
            : new Date(formik.values.dateAction).toISOString();
        let locationMedicalAssistance = isEditLocationMedicalAssistance
            ? formik.values.locationMedicalAssistanceEdit
            : formik.values.locationMedicalAssistance?.label;
        let item = {
            idEvent: idEvent,
            idHistory: idHistory,
            idPersonnel: selectedPersonnalValue.id,

            dateOfInjury: dateOfInjury,
            gravityOfInjury: gravityOfInjury ?? null,
            locationWhereInjury: locationWhereInjury ?? null,
            circumstances: formik.values.circumstances,
            nature: formik.values.natureOfDiagnosis,

            dateOfVisits: dateAction,
            locationMedicalAssistance: locationMedicalAssistance ?? null,
            complaint: formik.values.complaint,
            diagnosis: formik.values.diagnosis,
            diagnosticStudies: formik.values.diagnosticStudies,
            helpProvided: formik.values.assistanceProvided,
            recommendations: formik.values.recommendations,
            pharmacotherapy: formik.values.pharmacotherapy,
            doctor: formik.values.doctor,
            stayMode: formik.values.stayMode?.label,
            hospitalWard: formik.values.hospitalWard,
        }
        let res = await Upd300Async(item);
        // Валидация запроса
        if (!IsCode200(res)) {
            isOnline = false;
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setLoading(false);

                return;
            };

            alertWarn('Не удалось создать запись !');
            setLoading(false);

            return;
        }

        alertSuccess('Запись успешно обновлена!');
        setIsUpdate(false);
    };

    // Диалоговое окно выбора военнослужащего
    const [selectedPersonnalValue, setSelectedPersonnalValue] = useState({});
    const [personnalDlgOpen, setPersonnalDlgOpen] = useState(false);
    const [selectedEventValue, setSelectedEventValue] = useState({});
    const [eventDlgOpen, setEventDlgOpen] = useState(false);
    const fillPersonnalData = async (id) => {
        if (isVisibleFullName) {
            return;
        }

        let eventType = 'none';
        switch (props.eventType) {
            case 1:
                eventType = 'injury';
                break;
            case 2:
                eventType = 'trauma';
                break;
            case 3:
                eventType = 'disease';
                break;
            case 4:
                eventType = 'nuclear';
                break;
            case 5:
                eventType = 'bacterial';
                break;
            case 6:
                eventType = 'chemical';
                break;
            case 7:
                eventType = 'burn';
                break;
            case 8:
                eventType = 'frostbite';
                break;
            case 9:
                eventType = 'infectious';
                break;
        }
        let res = await GetEventAsync(eventType, id);
        // Валидация запроса
        if (!IsCode200(res) || res.result?.length == 0) {
            setStep(1);

            return;
        }

        setEvent(res.result);
        setEventDlgOpen(true);
    };
    const handlePersonnalDlgClose = async (val) => {
        setPersonnalDlgOpen(false);
        if (val === null) {
            setIsVisibleFullName(true);
            setStep(1);

            return;
        }
        if (val.fullName === undefined) return;
        setSelectedPersonnalValue(val);
        setFullNameValue(`${val.fullName}`);
        await fillPersonnalData(val.id);
    };
    const isEditEvent = (val) => {
        let dt1 = new Date(val);
        let dt2 = new Date();
        // Валидация
        if (dt1 >= dt2) {
            setIsUpdate(false);
            setIsDisabled(true);
            return true;
        }

        setIsDisabled(true)
        setIsUpdate(false);
        return false;
    };
    const fillEventByStageEvacuation = (val) => {
        let dataAction = null;
        let locationMedicalAssistance = '-';
        let complaint = '';
        let diagnosis = '';
        let diagnosticStudies = '';
        let assistanceProvided = '';
        let pharmacotherapy = '';
        let recommendations = '';
        let doctor = '';
        let stayMode = '-';
        let hospitalWard = '';

        if (isEditEvent(val.dataAction)) {
            dataAction = val.dataAction ?? dataAction;
            locationMedicalAssistance = val.locationMedicalAssistance ?? locationMedicalAssistance;
            complaint = val.complaint ?? complaint;
            diagnosis = val.diagnosis ?? diagnosis;
            diagnosticStudies = val.diagnosticStudies ?? diagnosticStudies;
            assistanceProvided = val.assistanceProvided ?? assistanceProvided;
            pharmacotherapy = val.pharmacotherapy ?? pharmacotherapy;
            recommendations = val.recommendations ?? recommendations;
            doctor = val.doctor ?? doctor;
            stayMode = val.stayMode ?? stayMode;
            hospitalWard = val.hospitalWard ?? hospitalWard;
        }

        handleSetDataAction(dataAction);
        let elemLocationMedicalAssistance = locationMedicalAssistanceJson.find(item => item.label === locationMedicalAssistance);
        formik.setFieldValue(model.locationMedicalAssistance.name, elemLocationMedicalAssistance);
        setLocationMedicalAssistance(elemLocationMedicalAssistance);
        formik.setFieldValue(model.complaint.name, complaint);
        formik.setFieldValue(model.diagnosis.name, diagnosis);
        formik.setFieldValue(model.diagnosticStudies.name, diagnosticStudies);
        formik.setFieldValue(model.assistanceProvided.name, assistanceProvided);
        formik.setFieldValue(model.pharmacotherapy.name, pharmacotherapy);
        formik.setFieldValue(model.recommendations.name, recommendations);
        formik.setFieldValue(model.doctor.name, doctor);
        let elemStayMode = stayModeJson.find(item => item.label === stayMode);
        formik.setFieldValue(model.stayMode.name, elemStayMode);
        setStayMode(elemStayMode);
        formik.setFieldValue(model.hospitalWard.name, hospitalWard);
    };
    const fillEventData = async (val) => {
        handleSetDateOfInjury(val.dateOfInjury);
        let gravityOfInjury = val.gravityOfInjury ?? '-';
        let elemGravityOfInjury = gravityOfInjuryJson.find(item => item.label === gravityOfInjury);
        formik.setFieldValue(model.gravityOfInjury.name, elemGravityOfInjury);
        setGravityOfInjury(elemGravityOfInjury);
        let locationWhereInjury = val.locationWhereInjury ?? '-';
        let elemLocationWhereInjury = locationWhereInjuryJson.find(item => item.label === locationWhereInjury);
        formik.setFieldValue(model.locationWhereInjury.autoCompleteCbx.name, elemLocationWhereInjury);
        setLocationWhereInjury(elemLocationWhereInjury);
        formik.setFieldValue(model.circumstances.name, val.circumstances ?? '');
        formik.setFieldValue(model.natureOfDiagnosis.name, val.nature ?? '');

        fillEventByStageEvacuation(val);
        setIdEvent(val.idEvent);
        setIdHistory(val.idHistory);
    };
    const handleEventDlgClose = async (val) => {
        setEventDlgOpen(false);
        if (val === null) {
            setStep(1);

            return;
        }
        if (val.idEvent === undefined) return;
        setSelectedEventValue(val);
        await fillEventData(val);

        setStep(1);
    };

    // Обработка возврата назад
    const handleBackClick = () => {
        navigate(urlPatientRegistration);
    };

    // Обработчик регистрации/обновления события
    const handleRegistration = async () => {
        setLoading(true);
        let answer;
        console.log("handleRegistration");
        if (isUpdate) {
            console.log("isUpdate");
            answer = await handleRecordUpdate();
        } else {
            console.log("NOT isUpdate");
            answer = await handleRecordInsert();
        }

        console.log(answer);
        setLoading(false);

        /**
         * doctors - храним в LS последних введённых врачей
         */

        let doctors = appStorage.getItem("doctors") || [];
        doctors.unshift(formik.values.doctor);
        doctors = [...new Set(doctors)];
        appStorage.setItem("doctors", doctors);
        // <==

        console.log(isOnline);
        setTimeout(() => {
            if (isValid && isOnline) {
                navigate(urlPatientRegistration)
            }

            isOnline = true;
        }, 600);
    };


    const model = {
        header: {
            thema: fields.collection2,
            label: GetModelByType().title,
        },
        birthday: {
            name: 'birthday',
        },
        tokenNumber: {
            name: 'tokenNumber',
        },
        dateOfInjury: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateOfInjury',
            label: GetModelByType().dateOfInjury,
        },
        gravityOfInjury: {
            thema: fields.collection2,
            size: 'small',
            name: 'gravityOfInjury',
            label: GetModelByType().gravityOfInjury,
            variant: 'outlined',
            elems: gravityOfInjuryJson,
        },
        locationWhereInjury: {
            textFld: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationWhereInjuryEdit',
                label: GetModelByType().locationWhereInjury,
                variant: 'outlined',
            },
            autoCompleteCbx: {
                thema: fields.collection2,
                size: 'small',
                name: 'locationWhereInjury',
                label: GetModelByType().locationWhereInjury,
                variant: 'outlined',
            },
            iconBtn: {
                thema: buttons.collection1,
            }
        },
        circumstances: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'circumstances',
            label: GetModelByType().circumstances,
            rows: '3',
        },
        natureOfDiagnosis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'natureOfDiagnosis',
            label: GetModelByType().natureOfDiagnosis,
            rows: '3',
        },
        dateAction: {
            thema: fields.collection2,
            size: 'small',
            type: 'date',
            variant: 'outlined',
            name: 'dateAction',
            label: 'Время поступления / обращения',
        },
        locationMedicalAssistance: {
            thema: fields.collection2,
            size: 'small',
            name: 'locationMedicalAssistance',
            label: 'Место оказания медицинской помощи',
            variant: 'outlined',
            required: true,
        },
        complaint: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'complaint',
            label: 'Жалобы',
            rows: '3',
        },
        diagnosis: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'diagnosis',
            label: 'Диагноз при поступлении',
            rows: '3',
        },
        diagnosticStudies: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'diagnosticStudies',
            label: 'Диагностические исследования и результаты',
            rows: '3',
        },
        assistanceProvided: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'assistanceProvided',
            label: 'Оказана помощь',
            rows: '3',
        },
        pharmacotherapy: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'pharmacotherapy',
            label: 'Фармакотерапия',
            rows: '3',
        },
        recommendations: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'recommendations',
            label: 'Рекомендации',
            rows: '3',
        },
        doctor: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'doctor',
            label: 'Лечащий врач',
        },
        stayMode: {
            thema: fields.collection2,
            size: 'small',
            name: 'stayMode',
            label: 'Режим пребывания',
            variant: 'outlined',
            elems: stayModeJson,
        },
        hospitalWard: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'hospitalWard',
            label: 'Этаж, крыло, палата',
        },
        backBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: handleBackClick,
            value: 'Назад',
        },
        nextBtn: {
            thema: buttons.collection1,
            icon: <MonitorHeartOutlinedIcon />,
            variant: 'contained',
            handleClick: handleNextClick,
            value: 'Далее',
        },
        insertBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: handleRegistration,
            value: 'Регистрация',
        },
        updateBtn: {
            thema: buttons.collection1,
            icon: <MedicalServicesOutlinedIcon />,
            variant: 'contained',
            handleClick: handleRegistration,
            value: 'Обновление',
        },
        PersonnalDlg: {
            title: 'Найдено совпадение по номеру жетона:',
            thema: dialogs.collection1
        },
        EventDlg: {
            title: 'Найденные истории болезни по номеру жетона:',
            thema: dialogs.collection1
        }
    };
    let context = {
        tokenNumber: '',
        surname: '',
        name: '',
        patronymic: '',
        birthday: '',
        phoneNumber: '',
        militaryUnit: { id: '', label: '' },
        militaryUnitEdit: '',
        division: { id: '', label: '' },
        divisionEdit: '',
        rota: { id: '', label: '' },
        rotaEdit: '',
        platoon: { id: '', label: '' },
        platoonEdit: '',
        department: { id: '', label: '' },
        departmentEdit: '',
        actualRank: { id: '', label: '' },
        actualRankEdit: '',
        typeOfDirection: '',
        dateOfInjury: new Date(),
        gravityOfInjury: { id: '', label: '' },
        locationWhereInjury: { id: '', label: '' },
        locationWhereInjuryEdit: '',
        circumstances: '',
        natureOfDiagnosis: '',
        dateAction: new Date(),
        locationMedicalAssistance: { id: '', label: '' },
        locationMedicalAssistanceEdit: '',
        complaint: 'боль в области ранения',
        diagnosis: '',
        diagnosticStudies: '',
        assistanceProvided: '',
        pharmacotherapy: '',
        recommendations: '',
        doctor: LSDoctors[0] || '',
        stayMode: { id: '', enum: '', label: '' },
        hospitalWard: '',
    };
    const validationSchema = Yup.object().shape({
    });

    const handleFormAction = (values) => {
    };
    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });
    if (doctors.length > 0) {
        if (!(doctors.length === 1 && formik.values.doctor === doctors[0])) {
            doctorsHelpers = doctors.filter(doctor => doctor);
        }
    }
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <Grid
                item xs={12} sm={12} md={12} lg={10} xl={10}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card
                        sx={{
                            mx: '5px',
                            py: '15px',
                            px: '10px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container spacing={2}
                        >
                            <Grid
                                style={{
                                    textAlign: 'center',
                                }}
                                item xs={12} sm={12} md={12} lg={12} xl={12}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>

                            </Grid>
                            <TokenNumber
                                formik={formik} tokenNumberOk={tokenNumberOk}
                                handleKeyUp={handleTokenNumberKeyUp} handleClick={handleTokenNumberClick}
                            />
                            {step >= 1 ?
                                isVisibleFullName ?
                                    <FullNameInput
                                        formik={formik}
                                        canEditName={true}
                                        militaryPartList={militaryPartJson} militaryPart={militaryPart} setMilitaryPart={setMilitaryPart} handleClickMilitaryPath={handleClickMilitaryPath} isEditMilitaryPath={isEditMilitaryPath}
                                        militaryUnitList={militaryUnitJson} militaryUnit={militaryUnit} setMilitaryUnit={setMilitaryUnit} handleClickMilitaryUnit={handleClickMilitaryUnit} isEditMilitaryUnit={isEditMilitaryUnit}
                                        militaryCompanyList={militaryCompanyJson} militaryCompany={militaryCompany} setMilitaryCompany={setMilitaryCompany} handleClickMilitaryCompany={handleClickMilitaryCompany} isEditMilitaryCompany={isEditMilitaryCompany}
                                        militaryPlatoonList={militaryPlatoonJson} militaryPlatoon={militaryPlatoon} setMilitaryPlatoon={setMilitaryPlatoon} handleClickMilitaryPlatoon={handleClickMilitaryPlatoon} isEditMilitaryPlatoon={isEditMilitaryPlatoon}
                                        militaryDepartmentList={militaryDepartmentJson} militaryDepartment={militaryDepartment} setMilitaryDepartment={setMilitaryDepartment} handleClickMilitaryDepartment={handleClickMilitaryDepartment} isEditMilitaryDepartment={isEditMilitaryDepartment}
                                        militaryRankList={militaryRankJson} militaryRank={militaryRank} setMilitaryRank={setMilitaryRank} handleClickMilitaryRank={handleClickMilitaryRank} isEditMilitaryRank={isEditMilitaryRank}
                                    /> :
                                    <>
                                        <FullNameShow
                                            fullNameValue={fullNameValue}
                                        />
                                    </> :
                                <></>
                            }
                            {step >= 1 ?
                                <>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={3} xl={3}
                                    >
                                        <DateTimeDesktopFld
                                            thema={model.dateOfInjury.thema} name={model.dateOfInjury.name} size={model.dateOfInjury.size}
                                            variant={model.dateOfInjury.variant} label={model.dateOfInjury.label}
                                            value={formik.values.dateOfInjury} isDisabled={isDisabled}
                                            handleBlur={formik.handleBlur} handleChange={handleSetDateOfInjury}
                                            touched={formik.touched.dateOfInjury} error={formik.errors.dateOfInjury}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={3} xl={3}
                                    >
                                        <AutoCompleteCbx
                                            thema={model.gravityOfInjury.thema} name={model.gravityOfInjury.name} size={model.gravityOfInjury.size}
                                            elems={model.gravityOfInjury.elems} variant={model.gravityOfInjury.variant} label={model.gravityOfInjury.label}
                                            isDisabled={isDisabled}
                                            value={formik.values.gravityOfInjury} val={gravityOfInjury}
                                            handleBlur={formik.handleBlur} setFieldValue={formik.setFieldValue} handleSetState={setGravityOfInjury}
                                            touched={formik.touched.gravityOfInjury} error={formik.errors.gravityOfInjury}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <ComboBoxEdit
                                            model={model.locationWhereInjury} elems={locationWhereInjuryJson} value={formik.values.locationWhereInjury}
                                            valueEdit={formik.values.locationWhereInjuryEdit} val={locationWhereInjury} isEdit={isEditLocationWhereInjury}
                                            isDisabled={isDisabled}
                                            setFieldValue={formik.setFieldValue} handleSetState={setLocationWhereInjury} handleClick={handleClickLocationWhereInjury}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleChangeEdit={formik.handleChange}
                                            touched={formik.touched.locationWhereInjury} touchedEdit={formik.touched.locationWhereInjuryEdit}
                                            error={formik.errors.locationWhereInjury} errorEdit={formik.errors.locationWhereInjuryEdit}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.circumstances.thema} size={model.circumstances.size} type={model.circumstances.type}
                                            variant={model.circumstances.variant} name={model.circumstances.name} label={model.circumstances.label}
                                            rows={model.circumstances.rows} value={formik.values.circumstances} isDisabled={isDisabled}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.circumstances} error={formik.errors.circumstances}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.natureOfDiagnosis.thema} size={model.natureOfDiagnosis.size} type={model.natureOfDiagnosis.type}
                                            variant={model.natureOfDiagnosis.variant} name={model.natureOfDiagnosis.name} label={model.natureOfDiagnosis.label}
                                            rows={model.natureOfDiagnosis.rows} value={formik.values.natureOfDiagnosis} isDisabled={isDisabled}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.natureOfDiagnosis} error={formik.errors.natureOfDiagnosis}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <DateTimeDesktopFld
                                            thema={model.dateAction.thema} name={model.dateAction.name} size={model.dateAction.size}
                                            variant={model.dateAction.variant} label={model.dateAction.label}
                                            value={formik.values.dateAction}
                                            handleBlur={formik.handleBlur} handleChange={handleSetDataAction}
                                            touched={formik.touched.dateAction} error={formik.errors.dateAction}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <AutoCompleteCbx
                                            thema={model.locationMedicalAssistance.thema} name={model.locationMedicalAssistance.name} size={model.locationMedicalAssistance.size}
                                            elems={locationMedicalAssistanceJson} variant={model.locationMedicalAssistance.variant} label={model.locationMedicalAssistance.label}
                                            value={formik.values.locationMedicalAssistance} val={locationMedicalAssistance}
                                            handleBlur={formik.handleBlur} setFieldValue={formik.setFieldValue} handleSetState={setLocationMedicalAssistance}
                                            touched={formik.touched.locationMedicalAssistance} error={formik.errors.locationMedicalAssistance}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.complaint.thema} size={model.complaint.size} type={model.complaint.type}
                                            variant={model.complaint.variant} name={model.complaint.name} label={model.complaint.label}
                                            rows={model.complaint.rows} value={formik.values.complaint}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.complaint} error={formik.errors.complaint}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.diagnosis.thema} size={model.diagnosis.size} type={model.diagnosis.type}
                                            variant={model.diagnosis.variant} name={model.diagnosis.name} label={model.diagnosis.label}
                                            rows={model.diagnosis.rows} value={formik.values.diagnosis}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange} handleClick={handleClickDiagnosis}
                                            touched={formik.touched.diagnosis} error={formik.errors.diagnosis}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.diagnosticStudies.thema} size={model.diagnosticStudies.size} type={model.diagnosticStudies.type}
                                            variant={model.diagnosticStudies.variant} name={model.diagnosticStudies.name} label={model.diagnosticStudies.label}
                                            rows={model.diagnosticStudies.rows} value={formik.values.diagnosticStudies}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.diagnosticStudies} error={formik.errors.diagnosticStudies}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.assistanceProvided.thema} size={model.assistanceProvided.size} type={model.assistanceProvided.type}
                                            variant={model.assistanceProvided.variant} name={model.assistanceProvided.name} label={model.assistanceProvided.label}
                                            rows={model.assistanceProvided.rows} value={formik.values.assistanceProvided}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.assistanceProvided} error={formik.errors.assistanceProvided}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.pharmacotherapy.thema} size={model.pharmacotherapy.size} type={model.pharmacotherapy.type}
                                            variant={model.pharmacotherapy.variant} name={model.pharmacotherapy.name} label={model.pharmacotherapy.label}
                                            rows={model.pharmacotherapy.rows} value={formik.values.pharmacotherapy}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.pharmacotherapy} error={formik.errors.pharmacotherapy}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <MultilineFld
                                            thema={model.recommendations.thema} size={model.recommendations.size} type={model.recommendations.type}
                                            variant={model.recommendations.variant} name={model.recommendations.name} label={model.recommendations.label}
                                            rows={model.recommendations.rows} value={formik.values.recommendations}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.recommendations} error={formik.errors.recommendations}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={3} xl={3}
                                    >
                                        <TextFld
                                            thema={model.doctor.thema} size={model.doctor.size} type={model.doctor.type}
                                            variant={model.doctor.variant} name={model.doctor.name} label={model.doctor.label}
                                            rows={model.doctor.rows} value={formik.values.doctor}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.doctor} error={formik.errors.doctor}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "12px",
                                                mt: "6px",
                                                color: "darkblue",
                                                textDecoration: "underline",
                                                "& div": {
                                                    cursor: "pointer",
                                                    // borderBottom: "1px dashed #000",
                                                    "::after": {
                                                        // content: '"  "',
                                                    }
                                                }
                                            }}>
                                            {doctorsHelpers.map(doctorText => {
                                                return <Box
                                                    onClick={() => handleClickDoctorHelper(doctorText)}
                                                >{doctorText}</Box>;
                                            })}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={3} xl={3}
                                    >
                                        <AutoCompleteCbx
                                            thema={model.stayMode.thema} name={model.stayMode.name} size={model.stayMode.size}
                                            elems={model.stayMode.elems} variant={model.stayMode.variant} label={model.stayMode.label}
                                            value={formik.values.stayMode} val={stayMode}
                                            handleBlur={formik.handleBlur} setFieldValue={formik.setFieldValue} handleSetState={setStayMode}
                                            touched={formik.touched.stayMode} error={formik.errors.stayMode}
                                        />
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={12} md={12} lg={6} xl={6}
                                    >
                                        <TextFld
                                            thema={model.hospitalWard.thema} name={model.hospitalWard.name} size={model.hospitalWard.size}
                                            type={model.hospitalWard.type} variant={model.hospitalWard.variant} label={model.hospitalWard.label}
                                            value={formik.values.hospitalWard}
                                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                                            touched={formik.touched.hospitalWard} error={formik.errors.hospitalWard}
                                        />
                                    </Grid>
                                </> :
                                <></>
                            }
                            <Grid
                                item xs={5} sm={5} md={3} lg={3} xl={3}
                            >
                                <StartIconBtn
                                    thema={model.backBtn.thema} icon={model.backBtn.icon} variant={model.backBtn.variant}
                                    value={model.backBtn.value}
                                    handleClick={model.backBtn.handleClick}
                                />
                            </Grid>
                            <Grid
                                item xs={2} sm={2} md={6} lg={6} xl={6}
                            />
                            <Grid
                                item xs={5} sm={5} md={3} lg={3} xl={3}
                            >
                                {loading === true ?
                                    <CircularRightPrg /> :
                                    step === 1 ?
                                        isUpdate ?
                                            <EndIconBtn
                                                thema={model.updateBtn.thema} icon={model.updateBtn.icon} variant={model.updateBtn.variant}
                                                value={model.updateBtn.value}
                                                handleClick={model.updateBtn.handleClick}
                                            /> :
                                            <EndIconBtn
                                                thema={model.insertBtn.thema} icon={model.insertBtn.icon} variant={model.insertBtn.variant}
                                                value={model.insertBtn.value}
                                                handleClick={model.insertBtn.handleClick}
                                            /> :
                                        <EndIconBtn
                                            thema={model.nextBtn.thema} icon={model.nextBtn.icon} variant={model.nextBtn.variant}
                                            value={model.nextBtn.value}
                                            handleClick={model.nextBtn.handleClick}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={0} lg={1} xl={1}
            />
            <PersonnalByInsertDlg
                thema={model.PersonnalDlg.thema} title={model.PersonnalDlg.title} elems={personnels}
                selectedValue={selectedPersonnalValue} open={personnalDlgOpen} onClose={handlePersonnalDlgClose} type={true}
            />
            <EventByInsertDlg
                thema={model.EventDlg.thema} title={model.EventDlg.title} elems={event}
                selectedValue={selectedEventValue} open={eventDlgOpen} onClose={handleEventDlgClose} type={true}
            />
        </Grid>
    )
}