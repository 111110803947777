import React from 'react';
import dayjs from 'dayjs';

import { fields, buttons, dialogs } from '../app/thema';

import {
    Grid, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemButton, ListItemAvatar,
    Avatar, ListItemText
} from '@mui/material';
import {
    TextFld, PasswordFld
} from '../components/Fields';
import {
    StartIconBtn, EndIconBtn
} from '../components/Buttons';

import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';


const getDateView = (val) => {
    let date = new Date(dayjs(val ?? undefined));
    if (val === undefined) {
        return '-';
    }

    return dayjs(date).format('DD.MM.YYYY');
}

const getDateTimeView = (val) => {
    let date = new Date(dayjs(val ?? undefined));
    if (val === undefined) {
        return '-';
    }

    return dayjs(date).format('DD.MM.YYYY HH:mm');
}

/**
 * Компонент диалоговое окно выбора личного состава
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PersonnalDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (val) => {
        onClose(val);
    };

    const GetPrimary = (val) => {
        return (
            <>
                {val.fullName}
            </>
        )
    }

    const GetSecondary = (val) => {
        return (
            <>
                Дата рождения: {getDateView(val.birthday)}
            </>
        )
    };

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item.birthday)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно выбора с возможностью добавления новой записи личного состава 
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PersonnalByInsertDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const GetPrimary = (val) => {
        return (
            <>
                {val.fullName}
            </>
        )
    }

    const GetSecondary = (val) => {
        return (
            <>
                Дата рождения: {getDateView(val.birthday)}
            </>
        )
    };

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const handleListItemNewClick = () => {
        onClose(null);
    }

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                <Grid
                    container spacing={1}
                >
                    <Grid
                        item
                    >
                        <ManageSearchIcon />
                    </Grid>
                    <Grid
                        item
                    >
                        {title}
                    </Grid>
                </Grid>
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemNewClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Добавить военнослужащего" />
                    </ListItemButton>
                </ListItem>
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно выбора/добавления события с этапом эвакуации
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function EventDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const GetPrimary = (item) => {
        return (
            <>
                Время обращения: {getDateTimeView(item.dateOfVisits)}<br />
                Место оказания медицинской помощи: {item.locationMedicalAssistance}
            </>
        )
    }

    const GetSecondary = (item) => {
        return (
            <>
                Диагноз: {item.diagnosis}
            </>
        )
    }

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <DescriptionIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно с возможностью добавления нового события с этапом эвакуации
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function EventByInsertDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const GetPrimary = (item) => {
        return (
            <>
                Время обращения: {getDateTimeView(item.dateOfVisits)}<br />
                Место оказания медицинской помощи: {item.locationMedicalAssistance}
            </>
        )
    }

    const GetSecondary = (item) => {
        return (
            <>
                Диагноз: {item.diagnosis}
            </>
        )
    }

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const handleListItemNewClick = () => {
        onClose(null);
    }

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                <Grid
                    container spacing={1}
                >
                    <Grid
                        item
                    >
                        <ManageSearchIcon />
                    </Grid>
                    <Grid
                        item
                    >
                        {title}
                    </Grid>
                </Grid>
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemNewClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Добавить историю болезни" />
                    </ListItemButton>
                </ListItem>
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <DescriptionIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно редактирования пользователя системы
 * @param { Object } props передаваемые параметры в компонент
 */
function UserEditDlg(props) {
    const { formik, open, handleClose, handleClick } = props

    const model = {
        title: 'Редактирование пользователя',
        fio: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'fio',
            label: 'Фамилия Имя Отчество',
        },
        mRankS: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'mRankS',
            label: 'Звание',
        },
        login: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'login',
            label: 'Логин',
        },
        password: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'password',
            label: 'Пароль',
        },
        lic: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'key',
            label: 'Ключ',
        },
        bntCancel: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Отменить',
        },
        btnAccept: {
            thema: buttons.collection1,
            icon: <SaveAsOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Обновить',
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="modalUserEditDescription"
            aria-describedby="modalUserEditDescription"
        >
            <DialogTitle id="modalUserEditDescriptionTitle">
                {model.title}
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.dialogEdit.fio.thema} name={model.dialogEdit.fio.name}
                            size={model.dialogEdit.fio.size} type={model.dialogEdit.fio.type}
                            variant={model.dialogEdit.fio.variant} label={model.dialogEdit.fio.label}
                            value={formik.values.fio}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.fio} error={formik.errors.fio}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.dialogEdit.mRankS.thema} name={model.dialogEdit.mRankS.name}
                            size={model.dialogEdit.mRankS.size} type={model.dialogEdit.mRankS.type}
                            variant={model.dialogEdit.mRankS.variant} label={model.dialogEdit.mRankS.label}
                            value={formik.values.mRankS}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.mRankS} error={formik.errors.mRankS}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.dialogEdit.login.thema} name={model.dialogEdit.login.name}
                            size={model.dialogEdit.login.size} type={model.dialogEdit.login.type}
                            variant={model.dialogEdit.login.variant} label={model.dialogEdit.login.label}
                            value={formik.values.login}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.login} error={formik.errors.login}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <PasswordFld
                            thema={model.dialogEdit.password.thema} name={model.dialogEdit.password.name}
                            size={model.dialogEdit.password.size} type={model.dialogEdit.password.type}
                            variant={model.dialogEdit.password.variant} label={model.dialogEdit.password.label}
                            value={formik.values.password}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.password} error={formik.errors.password}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.dialogEdit.lic.thema} name={model.dialogEdit.lic.name}
                            size={model.dialogEdit.lic.size} type={model.dialogEdit.lic.type}
                            variant={model.dialogEdit.lic.variant} label={model.dialogEdit.lic.label}
                            value={formik.values.lic}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.lic} error={formik.errors.lic}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    px: '24px',
                    pb: '15px'
                }}
            >
                <Grid
                    container
                >
                    <Grid
                        item xs={5} sm={3} md={3} lg={3} xl={3}
                    >
                        <StartIconBtn
                            thema={model.dialogEdit.bntCancel.thema} icon={model.dialogEdit.bntCancel.icon}
                            variant={model.dialogEdit.bntCancel.variant}
                            value={model.dialogEdit.bntCancel.value}
                            handleClick={handleClose}
                        />
                    </Grid>
                    <Grid
                        item xs={2} sm={6} md={6} lg={6} xl={6}
                    />
                    <Grid
                        item xs={5} sm={3} md={3} lg={3} xl={3}
                    >
                        <EndIconBtn
                            thema={model.dialogEdit.btnAccept.thema} icon={model.dialogEdit.btnAccept.icon}
                            variant={model.dialogEdit.btnAccept.variant}
                            value={model.dialogEdit.btnAccept.value}
                            handleClick={handleClick}
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}


export {
    PersonnalDlg, PersonnalByInsertDlg, EventDlg, EventByInsertDlg, UserEditDlg,
}