import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    urlDef, urlSignIn, urlSignOut,
    urlHome,
    urlRecent,
    urlPatientRegistration, urlFormaDeath, urlFormaInjury, urlFormaDiseases, urlFormaTrauma, urlFormaNuclear, urlFormaBacterial,
    urlFormaChemical, urlFormaBurn, urlFormaFrostbite, urlFormaInfectious,
    urlExport,
    urlHistory,
    urlEvacuation,
    urlPersonnel,
    urlManagement, urlMilitaryUsers, urlMilitaryOrganizations,
} from '../common/helper/UrlRouter';

import PAuthentication from '../pages/PAuthentication';
import PMain from '../pages/PMain';
import PRegistration from '../pages/PRegistration';
import PExport from '../pages/PExport';
import PHistory from '../pages/PHistory';
import PPersonnel from '../pages/PPersonnel';
import PEvacuation from '../pages/PEvacuation';
import PRecent from '../pages/PRecent';
import PManagement from '../pages/PManagement';


/**
 * Маршрутизация приложения
 * @returns готовая страница
 */
export default function router() {
    return (
        <Routes>
            <Route path={urlHome} element={<PMain />} />

            <Route path={urlDef} element={<PAuthentication />} />
            <Route path={urlSignIn} element={<PAuthentication />} />
            <Route path={urlSignOut} element={<PAuthentication />} />

            <Route path={urlRecent} element={<PRecent />} />

            <Route path={urlPatientRegistration} element={<PRegistration eventType={0} />} />
            <Route path={urlFormaInjury} element={<PRegistration eventType={1} />} />
            <Route path={urlFormaTrauma} element={<PRegistration eventType={2} />} />
            <Route path={urlFormaDiseases} element={<PRegistration eventType={3} />} />
            <Route path={urlFormaNuclear} element={<PRegistration eventType={4} />} />
            <Route path={urlFormaBacterial} element={<PRegistration eventType={5} />} />
            <Route path={urlFormaChemical} element={<PRegistration eventType={6} />} />
            <Route path={urlFormaBurn} element={<PRegistration eventType={7} />} />
            <Route path={urlFormaFrostbite} element={<PRegistration eventType={8} />} />
            <Route path={urlFormaInfectious} element={<PRegistration eventType={9} />} />
            <Route path={urlFormaDeath} element={<PRegistration eventType={10} />} />

            <Route path={urlExport} element={<PExport />} />
            <Route path={urlEvacuation} element={<PEvacuation />} />
            <Route path={urlPersonnel} element={<PPersonnel />} >
                <Route path={urlPersonnel + "/:patientId?/:action?"} element={<PPersonnel />} />
            </Route>
            <Route path={urlHistory} element={<PHistory />} >
                <Route path={urlHistory + "/:patientId?/:historyItemIndex?"} element={<PHistory />} />
            </Route>

            <Route path={urlManagement} element={<PManagement step={0} />} />
            <Route path={urlMilitaryUsers} element={<PManagement step={1} />} />
            <Route path={urlMilitaryOrganizations} element={<PManagement step={2} />} />
        </Routes>
    )
}