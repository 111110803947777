import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
    Autocomplete,
    Box,
    Typography,
    Grid,
    TextField,
} from '@mui/material';

import { debounce } from '@mui/material/utils';

import {
    sendSearch,
    addToSymptomsList,
    sendGetSymptomsFromText,
    sendGetPatientData,
} from "../../historySlice";
import { urlHistory } from "../../../../common/helper/UrlRouter";
import { useNavigate } from "react-router-dom";


const MIN_SEARCH_TEXT = 2;
const MAX_SEARCH_WORDS = 500;

const theme = createTheme({
    palette: {
        primary: { main: "#344C11" },
    },
});

const SearchComponent = ({ type, resultItemProps = {}, label = "", item = {}, itemIcon = "", showLevel = false, inputColor = "", variant = "inline", sx = {}, minSearchText = MIN_SEARCH_TEXT, currentValue = null }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [value, setValue] = useState(currentValue || null);
    const [optionsDisabled, setOptionsDisabled] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [inputValue, setInputValue] = useState(currentValue?.lable || '');
    const [options, setOptions] = useState([]);

    const handleKeyPress = (event) => {
        if (event.code === "Enter" || event.key === "Enter" || event.keyCode === "13") {
            if (!isHighlighted && variant === "parse") {
                dispatch(sendGetSymptomsFromText({ data: { text: inputValue, type } }));
                event.preventDefault();
                // event.preventDefault();
            }
        }
    }

    const fetch = useMemo(
        () =>
            debounce((request, success) => {
                dispatch(sendSearch({
                    data: {
                        ...request,
                        conceptId: item.id || null,
                        type
                    },
                    success,
                }));
            }, 400),
        [dispatch, item.id, type],
    );

    useEffect(() => {
        let active = true;


        if (inputValue === '' || inputValue.length <= minSearchText) {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const wordsCount = (inputValue.match(/\S+/g) || []).length;

        if (wordsCount > MAX_SEARCH_WORDS) {
            setOptions([]);
            return undefined;
        }

        fetch({ text: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, minSearchText]);

    return <>
        <ThemeProvider theme={theme}>
            <Autocomplete
                fullWidth
                filterSelectedOptions
                id="search1"
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.lable || ""
                }
                filterOptions={(x) => x}
                options={optionsDisabled ? [] : options}
                freeSolo={variant === "parse"}
                inputValue={inputValue}
                value={value}
                sx={{
                    outline: `4px solid ${inputColor}`,
                    borderRadius: "5px",
                    outlineOffset: "-4px",
                    ...sx,
                    '&:active': {
                        boxShadow: "red !important",
                        borderColor: "red !important",
                    },
                    "&:focused": {
                        "border-color": "red !important",
                        "box-shadow": "0 0 0 2px rgba(24, 144, 255, 0.2)"
                    },
                    "&:focus-visible": {
                        "border-color": "red !important",
                        "box-shadow": "0 0 0 2px rgba(24, 144, 255, 0.2)"
                    },
                    "& input": {
                        "border-color": "red !important",
                        "box-shadow": "0 0 0 2px rgba(24, 144, 255, 0.2)"
                    },
                    "&.input": {
                        "border-color": "red !important",
                        "box-shadow": "0 0 0 2px rgba(24, 144, 255, 0.2)"
                    },
                }}
                noOptionsText={"нет результатов"}

                onHighlightChange={(_, options) => {
                    setIsHighlighted(!!options?.lable);
                }}
                onChange={(_, newValue) => {
                    /**
                     * Проверка на тип добавлена из-за того, что при добавление заоблевания через парсинг срабатывает onChange
                     * @todo потом разобраться почему срабатывает onChange и убрать проверку (для примера взять симптомы, там в аналогичной ситуации событие не срабатывает)
                     */
                    if (typeof newValue === "object") {
                        if (type === "Symptoms" || type === "Diagnostic") {
                            console.log("onChange Symptoms");
                            dispatch(addToSymptomsList(newValue));
                        } else if (type === "TokenFIO") {
                            console.log("TokenFIO", newValue, type);

                            dispatch(sendGetPatientData({
                                data: newValue,
                                success: (data) => {
                                    navigate(`${urlHistory}/${data.personal?.id}`);
                                },
                            }));

                            // dispatch(addToPatientEditClarificationsList({ item, value: newValue, type }));
                        }
                    }

                    if (type !== "Unit") {
                        setInputValue("");
                        setValue(null);
                    }
                }}
                onKeyDownCapture={handleKeyPress}
                onInputChange={(_, newInputValue) => {

                    console.log(newInputValue);
                    const regex = /(\r\n|\r|\n)/gm;
                    const checkOptionsDisabled = regex.test(newInputValue);

                    if (checkOptionsDisabled !== optionsDisabled) {
                        setOptionsDisabled(checkOptionsDisabled);
                    }

                    setInputValue(newInputValue);
                    if (type === "Unit") {
                        setValue(newInputValue);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        label={label}
                        fullWidth
                        multiline={variant === "parse"}
                        {...params}
                    />
                )}

                renderOption={(props, option) => {
                    return (
                        <li {...props} key={props.id}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 30 }}>
                                    {itemIcon}
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    <Box component="span" {...resultItemProps}>
                                        {option.lable}
                                    </Box>
                                    {showLevel && option.levelName ? <Typography variant="body2" color="text.secondary">
                                        {option.levelName}
                                    </Typography> : null}
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        </ThemeProvider>
    </>;
}

export const Search = memo(SearchComponent);
