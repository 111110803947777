import React from 'react';

import { cards } from '../app/thema';
import { 
    urlFormaDeath, urlFormaInjury, urlFormaDiseases, urlFormaTrauma, urlFormaNuclear,
    urlFormaBacterial, urlFormaChemical, urlFormaBurn, urlFormaFrostbite, urlFormaInfectious,
} from '../common/helper/UrlRouter';

import { Grid, Avatar } from '@mui/material';

import { BtnCard, BtnCardImg } from '../components/Cards';


const model = {
    formaInjury: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/injury.jpg',
        title: 'Ранение',
        href: urlFormaInjury
    },
    formaTrauma: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/trauma.jpg',
        title: 'Травма',
        href: urlFormaTrauma
    },
    formaDiseases: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/diseases.jpg',
        title: 'Заболевания',
        href: urlFormaDiseases
    },
    formaNuclear: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/nuclear.jpg',
        title: 'Ядерное',
        href: urlFormaNuclear
    },
    formaBacterial: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/bacterial1.jpg',
        title: 'Бактериальное',
        href: urlFormaBacterial
    },
    formaChemical: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/chemical.jpg',
        title: 'Химическое',
        href: urlFormaChemical
    },
    formaBurn: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/burn.jpg',
        title: 'Ожог',
        href: urlFormaBurn
    },
    formaFrostbite: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/frostbite.jpg',
        title: 'Отморожение',
        href: urlFormaFrostbite
    },
    formaInfectious: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/infectious.jpg',
        title: 'Инфекция',
        href: urlFormaInfectious
    },
    formaDeath: {
        thema: cards.collection1,
        fontSize: '20pt',
        img: '/img/death.jpg',
        title: 'Форма 200',
        href: urlFormaDeath
    },
}

/**
 * Виджет выбора типа форм регистрации пациента
 * @returns виджет
 */
export default function SelectorForms() {
    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={2} xl={2}
            />
            <Grid
                item xs={12} sm={12} md={8} lg={8} xl={8}
            >
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaInjury.thema} fontSize={model.formaInjury.fontSize} img={model.formaInjury.img}
                            title={model.formaInjury.title} href={model.formaInjury.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaTrauma.thema} fontSize={model.formaTrauma.fontSize} img={model.formaTrauma.img}
                            title={model.formaTrauma.title} href={model.formaTrauma.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaDiseases.thema} fontSize={model.formaDiseases.fontSize} img={model.formaDiseases.img}
                            title={model.formaDiseases.title} href={model.formaDiseases.href}
                        />
                    </Grid>
                    {/*<Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaNuclear.thema} fontSize={model.formaNuclear.fontSize} img={model.formaNuclear.img}
                            title={model.formaNuclear.title} href={model.formaNuclear.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaBacterial.thema} fontSize={model.formaBacterial.fontSize} img={model.formaBacterial.img}
                            title={model.formaBacterial.title} href={model.formaBacterial.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaChemical.thema} fontSize={model.formaChemical.fontSize} img={model.formaChemical.img}
                            title={model.formaChemical.title} href={model.formaChemical.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaBurn.thema} fontSize={model.formaBurn.fontSize} img={model.formaBurn.img}
                            title={model.formaBurn.title} href={model.formaBurn.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaFrostbite.thema} fontSize={model.formaFrostbite.fontSize} img={model.formaFrostbite.img}
                            title={model.formaFrostbite.title} href={model.formaFrostbite.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaInfectious.thema} fontSize={model.formaInfectious.fontSize} img={model.formaInfectious.img}
                            title={model.formaInfectious.title} href={model.formaInfectious.href}
                        />
                    </Grid>*/}
                    <Grid
                        item xs={12} sm={12} md={12} lg={6} xl={6}
                    >
                        <BtnCardImg
                            thema={model.formaDeath.thema} fontSize={model.formaDeath.fontSize} img={model.formaDeath.img}
                            title={model.formaDeath.title} href={model.formaDeath.href}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={2} xl={2}
            />
        </Grid>
    )
}
