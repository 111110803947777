import React, { memo, useState, useMemo, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { fields, buttons, dialogs } from '../../../../app/thema';
import {
    Divider,
    Box,
    Stack,
    NativeSelect,
    List,
    ListItemButton,
    ListItemText,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Button,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    Checkbox,
} from '@mui/material';


import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Handbook300Async, MilitaryHandbooksAsync } from '../../../../common/api/Handbook';
import { DateTimeDesktopFld, MultilineFld } from '../../../../components/Fields';
import { EndIconBtn } from '../../../../components/Buttons';
import { AutoCompleteCbx } from '../../../../components/ComboBoxs';

import {
    selectCurrentHistoryItem,
    setCurrentHistoryItem,
    selectPatientData,
    updateCurrentHistoryItem,
    updatePatientData,
    sendUpdate300,
} from "../../historySlice";

import { PrintTpl_1 as PrintTpl1 } from "../PrintTpl_1";
import { PrintTpl_2 as PrintTpl2 } from "../PrintTpl_2";
import { PrintTpl_3 as PrintTpl3 } from "../PrintTpl_3";
import { PrintTpl_4 as PrintTpl4 } from "../PrintTpl_4";
import { PrintTpl_5 as PrintTpl5 } from "../PrintTpl_5";
import { PrintTpl_6 as PrintTpl6 } from "../PrintTpl_6";

import { PatientInfo } from "../PatientInfo";
// import { PrintBlock } from "../PrintBlock";
import { PrintMenu } from "../PrintMenu";

import "./styles.css";
import { lightGreen } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { urlHistory } from "../../../../common/helper/UrlRouter";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
    palette: {
        primary: { main: "#344C11" },
    },
});

const model = {
    status: {
        thema: fields.collection2,
        type: '',
        fieldKey: 'status',
        label: 'Статус',
    },
    location: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'location',
        label: 'Место оказания медицинской помощи',
    },
    hospitalizationDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'hospitalizationDate',
        label: 'Время поступления/обращения',
    },
    militaryRank: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'militaryRank',
        label: 'Воинское звание',
    },
    disease: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'disease',
        label: 'Диагноз при поступлении',
        rows: '4',
    },
    diagnosisСlinical: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisСlinical',
        label: 'Диагноз клинический',
        rows: '4',
    },
    diagnosisСlinicalFinal: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisСlinicalFinal',
        label: 'Диагноз клинический заключительный',
        rows: '4',
    },
    diagnosisStudies: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisStudies',
        label: 'Результаты диагностических исследований',
        rows: '4',
    },
    helping: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'helping',
        label: 'Оказана помощь',
        rows: '4',
    },
    pharmacotherapy: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'pharmacotherapy',
        label: 'Фармакотерапия',
        rows: '4',
    },
    risks: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'risks',
        label: 'Риски',
        rows: '4',
    },
    dischargeDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'dischargeDate',
        label: 'Время эвакуации/выписки',
    },
    evacuationLocation: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'evacuationLocation',
        label: 'Куда эвакуирован',
    },
    doctor: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'doctor',
        label: 'Лечащий врач',
        rows: '1',
    },
}

const ModalTextField = ({ name, sx = {}, ...props }) => {
    return <TextField
        margin="dense"
        size="small"
        id={name}
        name={name}
        type="text"
        variant="standard"
        sx={{
            m: 0,
            ml: 1,
            width: "80px",
            ...sx,
        }}
        {...props}
    />;
}

const ModalStack = ({ children }) => {
    return <Stack
        direction="row"
        sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            mx: 0,
            my: 1,
            "& p": {
                mt: 1,
            }
        }}>
        {children}
    </Stack>;
}

const ListItemComponent = ({ title, currentHistoryItem, editMode, formik, locationsList, militaryRanksList }) => {
    let key = title.fieldKey;

    const elems = {
        militaryRank: militaryRanksList,
        evacuationLocation: locationsList,
        location: locationsList,
    };

    const handleChangeDate = (key, val) => {
        let d = new Date(dayjs(val ?? undefined));

        d = d.getFullYear() === 1 ? new Date() : d.toISOString();
        formik.setFieldValue(key, d);
    }

    if (!editMode || !model[key] || !model[key].component) {
        return <ListItemButton>
            <ListItemText
                primary={title.label}
                secondary={title.type === "date" && currentHistoryItem[title.fieldKey] ? new Date(currentHistoryItem[title.fieldKey]).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : currentHistoryItem[title.fieldKey] || "-"}
            />
        </ListItemButton>;
    } else {
        if (model[key]) {
            const FieldComponent = model[key].component;

            return <Box
                sx={{ m: 2 }}>
                <FieldComponent
                    size={'small'}
                    variant={'outlined'}
                    thema={model[key].thema}
                    type={model[key].type}
                    name={key}
                    rows={model[key].rows || ""}
                    value={formik.values[key]}
                    isDisabled={false}
                    label={title.label}
                    handleBlur={formik.handleBlur}
                    handleChange={title.type === "date" ? (val) => handleChangeDate(key, val) : formik.handleChange}
                    touched={formik.touched[key]}
                    error={formik.errors[key]}
                    elems={elems[key] || []}
                    val={formik.values[key]}
                    setFieldValue={formik.setFieldValue}
                    handleSetState={() => { }}
                /></Box>;
        }
    }
}


const HistoryItemComponent = ({ currentEvent, notEnded, isLast }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let currentHistoryItem = useSelector(selectCurrentHistoryItem);
    const patientData = useSelector(selectPatientData);
    const eventData = patientData.events ? patientData.events[currentEvent] : {};
    const [tplId, setTplId] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    // const [locationState, setLocationState] = useState({ id: '0', label: "EMPTY" });
    const [modalData, setModalData] = useState({});
    const [locationsList, setLocationsList] = useState([]);
    const [militaryRanksList, setMilitaryRanksList] = useState([]);

    currentHistoryItem = {
        ...currentHistoryItem,
        militaryRank: patientData.militaryRank
    };

    let printData = {
        patientData,
        currentHistoryItem,
        currentEvent,
        modalData,
    };

    const handleFormAction = (values) => {
    };

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: Yup.object().shape({}),
        initialValues: {
            dischargeDate: null,
            disease: "", // diagnosis
            evacuationLocation: { id: '', label: '' },
            helping: "",
            hospitalizationDate: null,
            location: { id: '', label: '' },
            status: "",
            doctor: "",
            militaryRank: { id: '', label: '' },
        }
    });

    let fullPrint = false;

    if (notEnded === false && isLast === true) {
        fullPrint = true;
        console.log("fullPrint", fullPrint);
    }

    /**
     * shitfix
     * @description нужно добавить проверку. В условия к fullPrint нужно добавить проверку. Печатать можно всё, если не прошло 24 часа. А пока хардкодим всегда true
     */
    fullPrint = true;


    const handleCloseHistoryItem = () => {
        navigate(`${urlHistory}/${patientData.id}/`, {replace: false});
        dispatch(setCurrentHistoryItem({}));
        setEditMode(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleEdit = () => {
        if (!editMode) {
            Object.keys(model).forEach(key => {
                let value = currentHistoryItem[key] || "";
                value = model[key].type === "list" ? { id: "", label: value } : value;

                formik.setFieldValue(model[key].fieldKey, value);
            });
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    };

    const handleClickSave = () => {
        let saveData = {
            idEvent: eventData.id,
            idHistory: currentHistoryItem.id,
            item: {
                diagnosis: formik.values.disease,
                locationMedicalAssistance: formik.values.location?.label || "",
                locationWhere: formik.values.evacuationLocation?.label || "",
                dateOfVisits: formik.values.hospitalizationDate || null,
                dateOfEvacuation: formik.values.dischargeDate || null,
                helpProvided: formik.values.helping,
                doctor: formik.values.doctor,
                militaryRank: formik.values.militaryRank?.label || "",
                recommendations: currentHistoryItem.recommendations,
                circumstances: eventData.circumstances,
                complaint: eventData.complaint || currentHistoryItem.complaint || "",
                dateOfInjury: eventData.eventDate || null,
                gravityOfInjury: eventData.gravityOfInjury,
                idPersonnel: eventData.idPersonnel,
                locationWhereInjury: eventData.location,
                nature: eventData.nature,

                diagnosisСlinical: formik.values.diagnosisСlinical,
                diagnosisСlinicalFinal: formik.values.diagnosisСlinicalFinal,
                diagnosisStudies: formik.values.diagnosisStudies,
                pharmacotherapy: formik.values.pharmacotherapy,
                risks: formik.values.risks,
            }
        }

        dispatch(sendUpdate300({
            data: saveData,
            success: () => {
                dispatch(updateCurrentHistoryItem({
                    dischargeDate: formik.values.dischargeDate,
                    disease: formik.values.disease,
                    doctor: formik.values.doctor,
                    evacuationLocation: formik.values.evacuationLocation?.label || "",
                    helping: formik.values.helping,
                    hospitalizationDate: formik.values.hospitalizationDate,
                    location: formik.values.location?.label || "",

                    diagnosisСlinical: formik.values.diagnosisСlinical,
                    diagnosisСlinicalFinal: formik.values.diagnosisСlinicalFinal,
                    diagnosisStudies: formik.values.diagnosisStudies,
                    pharmacotherapy: formik.values.pharmacotherapy,
                    risks: formik.values.risks,
                }));

                dispatch(updatePatientData({
                    militaryRank: formik.values.militaryRank?.label || "",
                }));
                setEditMode(false);
            },
        }));
    };

    const handleClickPrint = (tpl, modal) => {
        if (!modal) {
            handlePrint(tpl);
        } else {
            setOpenModal(tpl);
            // handlePrint(tpl);
        }
    }

    const handlePrint = (tpl) => {
        setTplId(tpl);

        setTimeout(() => {
            window.print();
        }, 600);
    }

    const setLocations = (locations) => {
        setLocationsList(locations);
        if (formik.values.location?.label === '') {
            formik.setFieldValue("location", currentHistoryItem.location || "");
        }
        if (formik.values.evacuationLocation?.label === '') {
            formik.setFieldValue("evacuationLocation", currentHistoryItem.evacuationLocation || "");
        }
    }

    const setMilitaryRanks = (militaryRanks) => {
        setMilitaryRanksList(militaryRanks);
        if (formik.values.militaryRank?.label === '') {
            formik.setFieldValue("militaryRank", currentHistoryItem.militaryRank || "");
        }

    }

    const handlerDataLoader = async () => {
        let handbook300 = await Handbook300Async();
        let handbookMilitary = await MilitaryHandbooksAsync();
        console.log(handbookMilitary);

        if (handbook300.result) {
            setLocations(handbook300.result.locationWhere);
        }
        if (handbookMilitary.result) {
            setMilitaryRanks(handbookMilitary.result.militaryRank);
        }
    }

    useEffect(() => {
        handlerDataLoader();
    }, []);


    const printItems = [
        {
            title: "Амбулаторная карта",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(3)
        },
        {
            title: "Стационарный лист",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(4)
        },
        {
            title: "Переводной эпикриз",
            fullPrint: true,
            modal: true,
            onClick: () => handleClickPrint(2, true)
        },
        {
            title: "Выписной эпикриз",
            fullPrint: true,
            modal: true,
            onClick: () => handleClickPrint(1, true)
        },
        {
            title: "Форма 12",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(5)
        },
        {
            title: "Эвакуационная карта",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(6)
        },
    ];

    return <>
        <ThemeProvider theme={theme}>

            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const modalData = Object.fromEntries(formData.entries());

                        setModalData(modalData);
                        handleCloseModal();
                        handlePrint(openModal);
                        console.log(modalData);
                    },
                }}
            >
                {/* <DialogTitle>Subscribe</DialogTitle> */}
                <DialogContent>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p1',
                            }} />
                        <DialogContentText>
                            Справка о ранении (контузии, травме, увечье), в соответствии с указом Президента РФ №98 от 05.03.2023 г. <br />№ <ModalTextField name="p1_number" sx={{ width: "90px" }} /> от <ModalTextField name="p1_date" /> г. оформлена.
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p2',
                            }} />
                        <DialogContentText>
                            Справка на социальные выплаты в соответствии с Указом Президента РФ №98 не оформлялась.
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p3',
                            }} />
                        <DialogContentText>
                            Освидетельствован ВВК <ModalTextField name="p3_date" /> года, в соответствии с Перечнем, утвержденным постановлением Правительства Российской Федерации от 29.07.1998 года №855, увечье - в перечень
                            <NativeSelect
                                defaultValue="входит"
                                sx={{ ml: 1 }}
                                inputProps={{
                                    name: 'p3_entire',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value="входит">входит</option>
                                <option value="не входит">не входит</option>
                            </NativeSelect>
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p4',
                            }} />
                        <DialogContentText>
                            Медицинское освидетельствование на определение тяжести травмы (увечья) не проводилось.
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p5',
                            }} />
                        <DialogContentText>
                            Эвакуация:
                            <NativeSelect
                                defaultValue=""
                                sx={{ ml: 1 }}
                                inputProps={{
                                    name: 'p5_evaqType',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value=""></option>
                                <option value="сидя">сидя</option>
                                <option value="лёжа">лёжа</option>
                            </NativeSelect>,
                            очередь <ModalTextField name="p5_queue" />
                        </DialogContentText>

                    </ModalStack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} variant="contained">Закрыть</Button>
                    <Button type="submit" variant="contained">Печатать</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullScreen
                open={!!currentHistoryItem.id}
                onClose={handleCloseHistoryItem}
                TransitionComponent={Transition}
            >
                {tplId === 1 ? <PrintTpl1 printData={printData} /> : null}
                {tplId === 2 ? <PrintTpl2 printData={printData} /> : null}
                {tplId === 3 ? <PrintTpl3 printData={printData} /> : null}
                {tplId === 4 ? <PrintTpl4 printData={printData} /> : null}
                {tplId === 5 ? <PrintTpl5 printData={printData} /> : null}
                {tplId === 6 ? <PrintTpl6 printData={printData} /> : null}

                <div className="no-print">
                    <AppBar
                        sx={{
                            position: "fixed",
                        }}
                    >
                        <Toolbar
                            sx={{
                                justifyContent: "space-between",
                            }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseHistoryItem}
                                aria-label="close"
                            >
                                <ArrowBackIcon />
                            </IconButton>

                            <Box
                                sx={{ display: "flex" }}
                            >
                                <PrintMenu items={printItems} />

                                <Button
                                    variant="text"
                                    endIcon={editMode ? <CancelIcon /> : <EditIcon />}
                                    onClick={handleEdit}
                                    color="inherit"
                                >
                                    {editMode ? "Отменить" : "Редактировать"}
                                </Button>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ m: 10 }}></Box>

                    <PatientInfo
                        blockStyles={{
                            textAlign: "left",
                            border: "1px solid lightgray",
                            borderRadius: 2,
                            p: 1,
                            backgroundColor: lightGreen[50],
                            display: "inline-flex",
                        }}
                        editable
                    />

                    <List>
                        <form onSubmit={formik.handleSubmit}>
                            {
                                Object.keys(model).map(key => <Fragment
                                    key={key}>
                                    <ListItemComponent
                                        title={model[key]}
                                        currentHistoryItem={currentHistoryItem}
                                        editMode={editMode}
                                        formik={formik}
                                        // setLocationState={setLocationState}
                                        locationsList={locationsList}
                                        militaryRanksList={militaryRanksList}
                                    />
                                    <Divider />
                                </Fragment>)
                            }
                        </form>


                        {editMode ? <Box
                            sx={{ m: 2 }}>
                            <EndIconBtn
                                thema={buttons.collection1}
                                icon={<SaveIcon />}
                                variant={'contained'}
                                value={"Сохранить"}
                                handleClick={handleClickSave}
                            />
                        </Box>
                            :
                            <Box
                                sx={{ m: 2 }}
                            >
                                <PrintMenu
                                    items={printItems}
                                />
                            </Box>}
                        <Divider />
                    </List>
                </div>
            </Dialog>
        </ThemeProvider>
    </>;
}

export const HistoryItem = memo(HistoryItemComponent);
