import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Grid,
} from '@mui/material';


import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { MilitaryHandbooksAsync } from '../../../../common/api/Handbook';

import { ListItem, listModel } from "./components/ListItem"

import {
    selectPatientData,
    updatePatientData,
    sendUpdatePersonnel,
} from "../../personnelSlice";


import { PatientInfo } from "../PatientInfo";

import { lightGreen } from "@mui/material/colors";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { urlPersonnel } from "../../../../common/helper/UrlRouter";

const theme = createTheme({
    palette: {
        primary: { main: "#344C11" },
    },
});

const PatientEditComponent = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const patientData = useSelector(selectPatientData);
    const [editMode, setEditMode] = useState(false);

    const [lists, setLists] = useState([]);

    const handleFormAction = (values) => {
    };

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: Yup.object().shape({}),
        initialValues: {
            suid: "",
            surName: "",
            name: "",
            patronymic: "",
            birthday: null,
            phoneNumber: null,
            militaryUnitCode: { id: '', label: '' },
            division: { id: '', label: '' },
            rota: { id: '', label: '' },
            platoon: { id: '', label: '' },
            militaryDep: { id: '', label: '' },
            militaryRank: { id: '', label: '' },
        }
    });


    const handleEdit = () => {
        if (!editMode) {
            setEditMode(true);
        } else {
            setEditMode(false);
            navigate(`${urlPersonnel}/${patientData.id}`);
        }
    };

    const handleClickSave = () => {
        let saveData = {
            id: patientData.id,
            tokenNumber: formik.values.suid || "",
            suid: formik.values.suid || "", // дублируем с другим названием, потому что в разных местах по разному называется
            surname: formik.values.surName || "",
            surName: formik.values.surName || "", // дублируем с другим названием, потому что в разных местах по разному называется
            name: formik.values.name || "",
            patronymic: formik.values.patronymic || "",
            birthday: formik.values.birthday || null,
            phoneNumber: formik.values.phoneNumber || "",
            militaryUnit: formik.values.militaryUnitCode?.label || "",
            militaryUnitCode: formik.values.militaryUnitCode?.label || "", // дублируем с другим названием, потому что в разных местах по разному называется
            division: formik.values.division?.label || "",
            rota: formik.values.rota?.label || "",
            platoon: formik.values.platoon?.label || "",
            department: formik.values.militaryDep?.label || "",
            militaryDep: formik.values.militaryDep?.label || "", // дублируем с другим названием, потому что в разных местах по разному называется
            actualRank: formik.values.militaryRank?.label || "",
            militaryRank: formik.values.militaryRank?.label || "", // дублируем с другим названием, потому что в разных местах по разному называется
        }

        dispatch(sendUpdatePersonnel({
            data: saveData,
            success: () => {
                dispatch(updatePatientData(saveData));
                setEditMode(false);
            },
        }));
    };

    const handlerDataLoader = async () => {
        let handbookMilitary = await MilitaryHandbooksAsync();
        console.log(handbookMilitary);

        if (handbookMilitary.result) {
            setLists(handbookMilitary.result);
        }
    }

    const handleClickEdit = () => {
        handleEdit();
    }

    useEffect(() => {
        handlerDataLoader();
    }, []);

    // useEffect(() => {
    //     if (patientData.id) {
    //         if (editMode) {
    //             navigate(`${urlPersonnel}/${patientData.id}/edit`);
    //         } else {
    //             navigate(`${urlPersonnel}/${patientData.id}`);
    //         }
    //     }
    // }, [editMode, navigate, patientData.id]);

    useEffect(() => {
        if (params.action === "edit") {
            setEditMode(true);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(patientData).length > 0) {
            Object.keys(listModel).forEach(key => {
                let value = patientData[key] || "";
                value = listModel[key].type === "list" ? { id: "", label: value } : value;

                formik.setFieldValue(listModel[key].fieldKey, value);
                formik.values[listModel[key].fieldKey] = value;
            });
        }
    }, [patientData]);


    return <ThemeProvider theme={theme}>
        <Box>
            {!editMode ? <PatientInfo
                blockStyles={{
                    textAlign: "left",
                    border: "1px solid lightgray",
                    borderRadius: 2,
                    px: 3,
                    py: 1,
                    backgroundColor: lightGreen[50],
                    display: "inline-flex",
                }}
                bottomComponent={
                    <Button
                        variant="contained"
                        endIcon={<EditIcon />}
                        onClick={handleClickEdit}
                    >
                        {"Редактировать"}
                    </Button>
                }
            /> : <Box
                sx={{
                    m: 2,
                    "& button": {
                        mx: 1
                    }
                }}>
                <Button
                    variant="outlined"
                    endIcon={<EditIcon />}
                    onClick={handleClickEdit}
                >
                    {editMode ? "Отменить" : "Редактировать"}
                </Button>
                {editMode && <Button
                    endIcon={<SaveIcon />}
                    variant={'contained'}
                    onClick={handleClickSave}
                >
                    Сохранить
                </Button>}
            </Box>}

            {editMode && <form onSubmit={formik.handleSubmit}>
                <Grid
                    container
                    spacing={2}
                    sx={{ m: 1 }}
                >
                    {
                        Object.keys(listModel).map(key => <ListItem
                            key={key}
                            title={listModel[key]}
                            editMode={editMode}
                            formik={formik}
                            lists={lists}
                        />)
                    }
                </Grid>
            </form>}

        </Box>
    </ThemeProvider>;
}

export const PatientEdit = memo(PatientEditComponent);
