import React from 'react';

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchSendSaveSelected,
  fetchSendSaveRow,
  fetchSendGetEvacuationData,
  fetchSendSavePatientsEvacuationStamp,
} from "./evacuationAPI";
import { getGridSingleSelectOperators, GridEditSingleSelectCell, useGridApiContext } from "@mui/x-data-grid-pro";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// import { appStorage } from "../../helpers";


const initialState = {
  currentPatientId: null,
  currentHistoryItem: {},
  printList: [],
  patientEdit: {
    disease: [],
    lifesAnamnez: [],
  },
  patientData: {
    patientInfo: {},
    evacuations: [],
  },
  evacuationData: {
    table: {
      columns: [],
      rows: [],
    },
  },
  loading: {
    evacuationData: false,
  }
};
function CustomFilterSelect(props, items) {
  const { item, applyValue, focusElementRef } = props;

  const ratingRef = React.useRef(null);
  // React.useImperativeHandle(focusElementRef, () => ({
    // focus: () => {
    //   ratingRef.current
    //     .querySelector(`input[value="${Number(item.value) || ''}"]`)
    //     .focus();
    // },
  // }));

  const handleFilterChange = (event, newValue) => {
    console.log(event, newValue);
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >

      <FormControl fullWidth>
        <InputLabel id="simple-select-label">Значение</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={item.value}
          label="Значение"
          onChange={handleFilterChange}
          ref={ratingRef}
          variant='standard'
        >
          {items.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}

export const sendSavePatientsEvacuationStamp = createAsyncThunk(
  "evacuation/sendSavePatientsEvacuationStamp",
  async (props = {}, { getState }) => {
    const {
      data = {},
      success = () => { }
    } = props;

    const response = await fetchSendSavePatientsEvacuationStamp({
      data,
      success
    });

    return response;
  }
);


export const sendGetEvacuationData = createAsyncThunk(
  "evacuation/sendGetEvacuationData",
  async (props = {}, { getState }) => {
    const {
      success = () => { }
    } = props;

    const response = await fetchSendGetEvacuationData({
      data: {
      },
      success
    });

    return response;
  }
);

export const sendSaveSelected = createAsyncThunk(
  "evacuation/sendSaveSelected",
  async (props = {}, { getState }) => {
    const {
      selected,
      success = () => { }
    } = props;

    const response = await fetchSendSaveSelected({
      data: {
        guids: selected
      },
      success
    });

    return response;
  }
);

export const sendSaveRow = createAsyncThunk(
  "evacuation/sendSaveRow",
  async (props = {}, { getState }) => {
    const {
      row,
      success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendSaveRow({
      data: {
        row
      },
      success
    });

    return response;
  }
);



export const evacuationSlice = createSlice({
  name: "evacuation",
  initialState,
  reducers: {

    resetEvacuationData: (state, action) => {
      state.evacuationData = initialState.evacuationData;
    },

    setPrintList: (state, action) => {
      state.printList = action.payload || initialState.printList;
    },

  },

  extraReducers: (builder) => {
    builder


      .addCase(sendGetEvacuationData.pending, (state) => {
        state.status = "loading";
        state.loading.evacuationData = true;
      })

      .addCase(sendGetEvacuationData.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;

        console.log(action.payload);

        let responseData = {};
        let responseRows = action.payload?.rows || {};
        let responseColumns = action.payload?.columns || [];


        console.log(responseColumns);

        const fieldTypes = {
          Guid: "string",
          String: "string",
          DateTimeOffset: "dateTime",
          Date: "date",

          // не используются
          number: "number",
          // date: "date",
          // dateTime: "dateTime",
          boolean: "boolean",
          singleSelect: "singleSelect",
        }

        function CustomTypeEditComponent(props) {
          // console.log(props);
          const apiRef = useGridApiContext();


          const handleValueChange = async (event) => {

            // console.log(newRow);

            let currentValue = await apiRef.current.getCellValue(props.id, props.dependablefield);
            let currentParams = await apiRef.current.getCellParams(props.id, props.dependablefield);
            let currentDependencyValues = currentParams.colDef.dependencyValues || {};
            let currentListObject = currentDependencyValues[event.target.value] || currentParams.colDef.values;

            // В новом списке не может быть текущего значения, тогда очищаем
            if (!currentListObject.includes(currentValue)) {
              await apiRef.current.setEditCellValue({
                id: props.id,
                field: props.dependablefield,
                value: '',
              });
            }
          };

          return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
        }

        let widths = {
          count: 10,
          fio: 180,
          jp_military_unit: 70,
          h300_evacuation_type: 60,
          h300_hospital_ward: 60,
          h300_referral: 90,
        };

        responseColumns = responseColumns.map(column => {
          let type = column.fieldType;
          let valueOptions = [];

          let width;

          if (column.values && column.values.length) {
            type = "singleSelect";
            valueOptions = column.values;
            // valueOptions = column.values && column.values.length && !column.values.includes("") && !column.values.includes("-") ? ["", ...column.values] : column.values;
          }

          if (widths[column.field]) {
            width = widths[column.field];
          }




          /**
           * >>>>>>
           */


          let h300_notes = responseRows.map(row => row.h300_notes) || [];
          h300_notes = [...new Set(h300_notes)];

          if (column.field === 'h300_notes') {
            return {
              ...column,
              filterOperators: getGridSingleSelectOperators()
                .map((operator) => ({
                  ...operator,
                  InputComponent: operator.InputComponent
                    ? (props) => CustomFilterSelect(props, h300_notes)
                    : undefined,
                })),
            };

          }


          /**
           * <<<<<<
           */

          // console.log(column);
          return {
            width,
            ...column,
            type: fieldTypes[type] || "string",
            // valueGetter: column.fieldType === "DateTimeOffset" || column.fieldType === "Date" ? (value) => value && new Date(value) : null,
            valueFormatter: (value) => {
              if (column.fieldType === "DateTimeOffset") {
                return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
              }
              if (column.fieldType === "Date") {
                return value && new Date(value).toLocaleDateString(undefined, {

                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                });
              }
              return value;
            },
            cellClassName: () => column.editable ? "editable-cell" : "",
            valueOptions: ({ field, id, row }) => {

              // Предполагаем, что это выпадающий список в фильтре с зависимыми полями. Собираем туда все варианты, которые могут быть.
              if (column?.dependencyField && (!id && !row)) {
                let column = responseColumns.find(column => column.field === field) || {};
                let dependencyValues = column.dependencyValues || {};
                let allDependencyOptions = [...column.values];
                let entries = Object.values(dependencyValues);

                entries.forEach(entry => {
                  allDependencyOptions = [...entry, ...allDependencyOptions];
                });

                allDependencyOptions = [...new Set(allDependencyOptions)]; // оставляем уникальные значения
                allDependencyOptions = allDependencyOptions.filter(option => option !== ""); // пустое удаляем, т.е. движок добавит своё пустое

                return allDependencyOptions.length ? allDependencyOptions : valueOptions;
              }

              if (column.dependencyField) {
                let dependencyValue = row ? row[column.dependencyField] : "";

                if (dependencyValue) {
                  return column.dependencyValues[dependencyValue] || valueOptions;
                } else {
                  return [];
                }

                // console.log(row);
                // console.log(column.dependencyField);
                // console.log(row[column.dependencyField]);
                // console.log(column.dependencyValues);
                // console.log(column.dependencyValues[dependencyValue]);
              }

              return valueOptions;
            },
            renderEditCell: column.field === "h300_status" ? (params) => <CustomTypeEditComponent {...params} dependablefield={"h300_location2"} /> : undefined,
          }
        });


        console.log(responseColumns);

        responseColumns.unshift({
          dependencyField: null,
          disableColumnMenu: true,
          dependencyValues: null,
          editable: false,
          editableFields: null,
          field: "count",
          fieldType: "String",
          groupChange: false,
          headerName: "№",
          hidden: false,
          sortable: false,
          toPrint: true,
          type: "string",
          width: 40,
          disableReorder: true,
          // valueGetter: (value, row) => { return i++; console.log(value, row) },
          renderCell: (value, row) => {
            let index = value.api.getRowIndexRelativeToVisibleRows(value.id);

            return index + 1;
          },
          values: null,
        })

        responseData = {
          "table": {
            "columns": responseColumns,
            "rows": responseRows,
          }
        };

        // responseRows.length = 10;

        console.log(responseData);


        state.evacuationData = responseData;
      })

      .addCase(sendGetEvacuationData.rejected, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;
      })



      .addCase(sendSaveSelected.pending, (state) => {
        state.status = "loading";
        state.loading.evacuationData = true;
      })

      .addCase(sendSaveSelected.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;

        state.evacuationData.table.rows = state.evacuationData?.table?.rows?.map(item => {
          return {
            ...item,
            h300_status: action.meta?.arg?.selected?.includes(item.h300_id) ? "Эвакуирован" : item.h300_status
          }
        })
      })

      .addCase(sendSaveSelected.rejected, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;
      })



      .addCase(sendSavePatientsEvacuationStamp.pending, (state) => {
        state.status = "loading";
        state.loading.evacuationData = true;
      })

      .addCase(sendSavePatientsEvacuationStamp.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;

        // console.log(action.payload);
        // let oldRows = state.evacuationData?.table?.rows || [];

        // let updatedEvacuationData = oldRows.map(row => {



        //   let newData = action.meta?.arg?.data?.h300_id?.includes(row.h300_id) ? action.payload : {};

        //   let { alert, h300_id, ...cleanData } = newData;

        //   if (action.meta?.arg?.data?.h300_id?.includes(row.h300_id)) {
        //     console.log(action.meta);
        //     console.log(action.meta?.arg?.data?.h300_id);
        //     console.log(newData);
        //     console.log(cleanData);
        //     console.log({
        //       ...row,
        //       ...newData,
        //     });
        //   }
        //   return {
        //     ...row,
        //     ...newData,
        //   }
        // });

        // console.log(updatedEvacuationData);

        // state.evacuationData = {
        //   table: {
        //     ...state.evacuationData.table,
        //     rows: updatedEvacuationData,
        //   }
        // };

      })

      .addCase(sendSavePatientsEvacuationStamp.rejected, (state, action) => {
        state.status = "idle";
        state.loading.evacuationData = false;
      })

      ;
  },
});

export const {
  resetEvacuationData,
  setPrintList,
} = evacuationSlice.actions;


export const selectEvacuationData = (state) => state.evacuation.evacuationData;
export const selectLoading = (state) => state.evacuation.loading.evacuationData;
export const selectPrintList = (state) => state.evacuation.printList;

export default evacuationSlice.reducer;
