import config from '../../app/config.json';

/**
 * Формирование строки подключения к серверу
 * prd - продуктовый сервер;
 * dev - сервер разработки;
 * loc - локальный сервер;
 * @returns 
 */
function GetUrlServer() {
    let result = '';

    switch (config.type) {
        case 'prd':
            result = `${config.server.prd.type}${config.server.prd.url}${config.server.prd.port}`;
            break;
        case 'dev':
            result = `${config.server.dev.type}${config.server.dev.url}${config.server.dev.port}`;
            break;
        case 'stb':
            result = `${config.server.stb.type}${window.location.hostname}${config.server.stb.port}`;
            break;
        case 'loc':
            result = `${config.server.loc.type}${config.server.loc.url}${config.server.loc.port}`;
            break;
        default:
            break;
    }

    return result;
}

const queryMethod = {
    put: `PUT`,
    post: `POST`,
    delete: `DELETE`,
    get: `GET`,
};


/**
 * Базовый адрес к сервису REST API
 */
const api = `${GetUrlServer()}/Api`;

/**
 * REST API Контроллер взаимодействия с биллингом
 */
const billingController = `${api}/Billing`;
/**
 * API для аутентификации пользователя
 * @returns 
 */
function UserCheckPOST() {
    return {
        url: `${billingController}/Authorization`,
        method: queryMethod.post,
    }
}

/**
 * REST API Контроллер для взаимодействия с документами
 */
const DocumentsController = `${api}/Documents`;
/**
 * API для формирования документа переводного эпикриза
 * @returns 
 */
function GetTransferableEpicrisisPOST() {
    return {
        url: `${DocumentsController}/GetTransferableEpicrisis`,
        method: queryMethod.post,
    };
}
/**
 * API для формирования документа выписки в строй
 * @returns 
 */
function GetMilitaryDischargePOST() {
    return {
        url: `${DocumentsController}/GetMilitaryDischarge`,
        method: queryMethod.post,
    };
}
/**
 * API для формирования документа амбулаторная карта
 * @returns 
 */
function GetPatientCardPOST() {
    return {
        url: `${DocumentsController}/GetPatientCard`,
        method: queryMethod.post,
    };
}
/**
 * API для формирования документа стационарный лист
 * @returns 
 */
function GetStationarySheetPOST() {
    return {
        url: `${DocumentsController}/GetStationarySheet`,
        method: queryMethod.post,
    };
}

/**
 * REST API Контроллер для взаимодействия с экспортом
 */
const ExportController = `${api}/Export`;
/**
 * API для экспорта статистики в CSV файла (Svetozar)
 * @returns 
 */
function GetExportPOST() {
    return {
        url: `${ExportController}/StatisticSummaryTableFile`,
        method: queryMethod.post,
    };
}

/**
 * REST API Контроллер взаимодействия с формами
 */
const formController = `${api}/Forms`;
// Forma 300
/**
 * API для обновления формы 300
 * @returns 
 */
function Get300POST() {
    return {
        url: `${formController}/Get300`,
        method: queryMethod.post,
    };
}
function Get300ListPOST() {
    return {
        url: `${formController}/Get300List`,
        method: queryMethod.post,
    };
}
/**
 * API для регистрации формы 300
 * @returns 
 */
function Reg300POST() {
    return {
        url: `${formController}/Reg300`,
        method: queryMethod.post,
    };
}
/**
 * API для обновления формы 300
 * @returns 
 */
function Upd300POST() {
    return {
        url: `${formController}/Upd300`,
        method: queryMethod.put,
    };
}
// Forma 200
/**
 * API для обновления формы 200
 * @returns 
 */
function Get200POST() {
    return {
        url: `${formController}/Get200`,
        method: queryMethod.post,
    }
}
/**
 * API для регистрации формы 200
 * @returns 
 */
function Reg200POST() {
    return {
        url: `${formController}/Reg200`,
        method: queryMethod.post,
    }
}
/**
 * API для обновления формы 200
 * @returns 
 */
function Upd200POST() {
    return {
        url: `${formController}/Upd200`,
        method: queryMethod.post,
    }
}
// Forma Diseases
/**
 * API для получения заболеваний
 * @returns 
 */
function GetDiseasesPOST() {
    return {
        url: `${formController}/GetDiseases`,
        method: queryMethod.post,
    };
}
/**
 * API для регистрации заболеваний
 * @returns 
 */
function RegDiseasesPOST() {
    return {
        url: `${formController}/RegDiseases`,
        method: queryMethod.post,
    };
}
/**
 * API для обновления заболеваний
 * @returns 
 */
function UpdDiseasesPOST() {
    return {
        url: `${formController}/UpdDiseases`,
        method: queryMethod.post,
    };
}
// Forma Trauma
/**
 * API для получения травм
 * @returns 
 */
function GetTraumaPOST() {
    return {
        url: `${formController}/GetTrauma`,
        method: queryMethod.post,
    };
}
/**
 * API для регистрации травм
 * @returns 
 */
function RegTraumaPOST() {
    return {
        url: `${formController}/RegTrauma`,
        method: queryMethod.post,
    };
}
/**
 * API для обновления травм
 * @returns 
 */
function UpdTraumaPOST() {
    return {
        url: `${formController}/UpdTrauma`,
        method: queryMethod.post,
    };
}

/**
 * API для получения всех событий и последнего этапа в рамках этого события
 * @returns 
 */
function GetEventPOST() {
    return {
        url: `${formController}/GetEvent`,
        method: queryMethod.post,
    };
}
/**
 * API для регистрации события или этапа в рамках этого события
 * @returns 
 */
function RegEventPOST() {
    return {
        url: `${formController}/RegEvent`,
        method: queryMethod.post,
    };
}
/**
 * API для обновления события или этапа в рамках этого события
 * @returns 
 */
function UpdEventPOST() {
    return {
        url: `${formController}/UpdEvent`,
        method: queryMethod.post,
    };
}

/**
 * REST API Контроллер для взаимодействия со справочниками
 */
const handbookController = `${api}/Handbook`
/**
 * API для получения списка военских частей
 * @returns 
 */
function MilitaryPartGET() {
    return {
        url: `${handbookController}/MilitaryPart`,
        method: queryMethod.get,
    }
}
/**
 * API для получения списка подразделений
 * @returns 
 */
function MilitaryUnitGET() {
    return {
        url: `${handbookController}/MilitaryUnit`,
        method: queryMethod.get,
    }
}
/**
 * API для получения списка рот
 * @returns 
 */
function MilitaryCompanyGET() {
    return {
        url: `${handbookController}/MilitaryCompany`,
        method: queryMethod.get,
    }
}
/**
 * API для получения списка взводов
 * @returns 
 */
function MilitaryPlatoonGET() {
    return {
        url: `${handbookController}/MilitaryPlatoon`,
        method: queryMethod.get,
    }
}
/**
 * API для получения списка отделений
 * @returns 
 */
function MilitaryDepartmentGET() {
    return {
        url: `${handbookController}/MilitaryDepartment`,
        method: queryMethod.get,
    }
}
/**
 * API для получения списка воинских званий
 * @returns 
 */
function MilitaryRankGET() {
    return {
        url: `${handbookController}/MilitaryRank`,
        method: queryMethod.get,
    }
}
/**
 * API для получения справочников для личного состава
 * @returns 
 */
function MilitaryHandbooksGET() {
    return {
        url: `${handbookController}/MilitaryHandbooks`,
        method: queryMethod.get,
    }
}
/**
 * API для получения справочников локаций для формы 300
 * @returns 
 */
function Handbook300POST() {
    return {
        url: `${handbookController}/Handbook300`,
        method: queryMethod.post,
    }
}
/**
 * API для получения справочников локаций для формы 300
 * @returns 
 */
function Handbook200GET() {
    return {
        url: `${handbookController}/Handbook200`,
        method: queryMethod.get,
    }
}

/**
 * REST API Контроллер для получения доп. информации
 */
const infoController = `${api}/Info`
/**
 * API для получения списка военских частей
 * @returns 
 */
function MilitaryPartPOST() {
    return {
        url: `${infoController}/MilitaryUnits`,
        method: queryMethod.post,
    }
}
/**
 * API для получения списка Куда эвакуирован
 * @returns 
 */
function EvacuationLocationsPOST() {
    return {
        url: `${infoController}/EvacuationLocations`,
        method: queryMethod.get,
    }
}
/**
 * API для получения списка Место оказания медицинской помощи
 * @returns 
 */
function LocationsPOST() {
    return {
        url: `${infoController}/Hospitals`,
        method: queryMethod.get,
    }
}

/**
 * REST API Контроллер для управления сервером
 */
const Management = `${api}/Management`;
/**
 * API для получения списка пользователей
 * @returns 
 */
function GetUsersPOST() {
    return {
        url: `${Management}/Users`,
        method: queryMethod.post,
    };
}
/**
 * API добавления/обновления пользователей
 * @returns 
 */
function GetUserEditPOST() {
    return {
        url: `${Management}/UserEdit`,
        method: queryMethod.post,
    };
}

/**
 * REST API Контроллер взаимодействия с журналом личного состава
 */
const personnelController = `${api}/Personnel`;
/**
 * API для добавления новой записи в журнале личного состава
 * @returns 
 */
function RegPersonnelPUT() {
    return {
        url: `${personnelController}/Reg`,
        method: queryMethod.put,
    }
}
/**
 * API для поиска записей по номеру жетона
 * @returns 
 */
function FindByTokenNumberPOST() {
    return {
        url: `${personnelController}/FindByTokenNumber`,
        method: queryMethod.post,
    };
}
/**
 * API для поиска записей по позывному
 * @returns 
 */
function FindByCallSignPOST() {
    return {
        url: `${personnelController}/FindByCallSign`,
        method: queryMethod.post,
    };
}
/**
 * API для поиска записи по Номеру жетона или ФИО
 */
function FindByStringPOST() {
    return {
        url: `${personnelController}/FindByString`,
        method: queryMethod.post,
    };
}
/**
 * API для побуквенного поиска (Svetozar)
 */
function CheckKeyPOST() {
    return {
        url: `${personnelController}/FindByLetters`,
        method: queryMethod.post,
    };
}
/**
 * API для генерации номера жетона
 * @returns 
 */
function GenerateTokenNumberPOST() {
    return {
        url: `${personnelController}/GenerateTokenNumber`,
        method: queryMethod.post,
    };
}

export {
    GetUrlServer,
    // REST API Контроллер взаимодействия с биллингом
    UserCheckPOST,
    // REST API Контроллер для взаимодействия с документами
    GetTransferableEpicrisisPOST, GetMilitaryDischargePOST, GetPatientCardPOST, GetStationarySheetPOST,
    // REST API Контроллер для взаимодействия с экспортом
    GetExportPOST,
    // REST API Контроллер взаимодействия с формами
    Get300POST, Get300ListPOST, Reg300POST, Upd300POST, Get200POST, Reg200POST, Upd200POST, GetDiseasesPOST,
    RegDiseasesPOST, UpdDiseasesPOST, GetTraumaPOST, RegTraumaPOST, UpdTraumaPOST, GetEventPOST, RegEventPOST,
    UpdEventPOST,
    // REST API Контроллер для взаимодействия со справочниками
    MilitaryPartGET, MilitaryUnitGET, MilitaryCompanyGET, MilitaryPlatoonGET, MilitaryDepartmentGET,
    MilitaryRankGET, MilitaryHandbooksGET, Handbook300POST, Handbook200GET,
    // REST API Контроллер для получения доп. информации
    MilitaryPartPOST, EvacuationLocationsPOST, LocationsPOST,
    // REST API Контроллер для управления сервером
    GetUsersPOST, GetUserEditPOST,
    // REST API Контроллер взаимодействия с журналом личного состава
    RegPersonnelPUT, FindByTokenNumberPOST, FindByCallSignPOST, FindByStringPOST, CheckKeyPOST,
    GenerateTokenNumberPOST,
};
